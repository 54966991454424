export default function mergeDiagrams(diagrams) {
  let finalDiagram = { nodes: [], edges: [], groups: [] }

  // Step 1: Merge all the diagrams into one.
  for (const diagram of diagrams) {
    finalDiagram = {
      nodes: finalDiagram.nodes.concat(diagram.nodes),
      edges: finalDiagram.edges.concat(diagram.edges),
      groups: finalDiagram.groups.concat(diagram.groups)
    }
  }

  // Step 2: Get all the location groups, take their IDs and identify all the locations.
  const locationGroups = finalDiagram.groups.filter(
    ({ location, parent }) => location !== undefined && parent === undefined
  )
  const locationGroupsIDs = locationGroups.map(({ id }) => id)
  const locations = Array.from(new Set(locationGroups.map(({ location }) => location)))

  const usedGroupsForLocations = {}

  // Step 3: Make a choice to use one group per location.
  for (const location of locations) {
    usedGroupsForLocations[location] = locationGroups.find(
      (group) => group.location === location
    ).id
  }

  // Replace the IDs of the `parent` for each location with the ID used for that location.
  for (const locationGroup of locationGroups) {
    const { id, location } = locationGroup
    const idForLocation = usedGroupsForLocations[location]
    if (id !== idForLocation) {
      for (let n = 0; n < finalDiagram.nodes.length; n++) {
        const node = finalDiagram.nodes[n]
        if (node.parent === id) {
          finalDiagram.nodes[n].parent = idForLocation
        }
      }
      for (let c = 0; c < finalDiagram.groups.length; c++) {
        const group = finalDiagram.groups[c]
        if (group.parent === id) {
          finalDiagram.groups[c].parent = idForLocation
        }
      }
    }
  }

  // Step 4: Remove unused groups.
  finalDiagram.groups = finalDiagram.groups.filter(({ id, location }) => {
    if (!location) {
      return true
    }

    return !(locationGroupsIDs.includes(id) && usedGroupsForLocations[location] !== id)
  })

  return finalDiagram
}
