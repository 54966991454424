import { defineStore } from 'pinia'

export const useAppStore = defineStore({
  id: 'app',

  state: () => ({
    loading: {
      max: 0,
      val: 0
    }
  }),

  actions: {
    resetLoading() {
      this.loading = {
        max: 0,
        val: 0
      }
    },
    increaseMaxLoading(inc = 1) {
      this.loading.max += inc
    },
    increaseValLoading(inc = 1) {
      this.loading.val += inc
    }
  }
})
