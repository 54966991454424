import instance from '@/api'
import { organizationsApiUrl } from '.'

export default {
  /**
   * Get recommendations.
   * @param {Number} organizationID
   * @param {String} provider
   * @param {Number} providerAccountID
   * @param {String} category
   * @param {String} subcategory
   * @returns {Promise<AxiosResponse<Array<Object>>>}
   */
  getRecommendations(organizationID, provider, providerAccountID, category, subcategory) {
    return instance.post(
      `${organizationsApiUrl}/${organizationID}/provider_accounts/${providerAccountID}/${provider}/recommendations`,
      {
        category,
        subcategory
      }
    )
  },

  /**
   * Get AWS savings plans.
   * @param {Number} organizationID
   * @param {Number} providerAccountID
   * @param {String} service
   * @returns {Promise<AxiosResponse<Array<Object>>>}
   */
  getAWSSavingsPlans(organizationID, providerAccountID, service) {
    return instance.post(
      `${organizationsApiUrl}/${organizationID}/provider_accounts/${providerAccountID}/aws/recommendations/savings_plans`,
      {
        service
      }
    )
  }
}
