import { instance, oauthInstance } from '@/api'
import mainServerUrl from '@/api/modules/main-server'

export const accountApiUrl = mainServerUrl + 'account'

// this one need to be exported because it is used in the interceptor
export const loginUrl = mainServerUrl + 'token'

export default {
  /**
   * Register a user.
   * @param {Object} data New user's payload.
   * @param {String} captcha ReCaptcha v3 string.
   * @returns {Promise<AxiosResponse>}
   */
  register(data, captcha) {
    return instance.post(accountApiUrl + '/register', data, {
      params: {
        captcha
      }
    })
  },

  /**
   * User asks to start the reset password procedure.
   * @param {Object} data `email`
   * @param {String} captcha Captcha string.
   * @returns {Promise<AxiosResponse>}
   */
  resetPassword(data, captcha) {
    return instance.post(accountApiUrl + '/ask_reset_password', data, {
      params: {
        captcha
      }
    })
  },

  /**
   * User edits their password.
   * @param {Object} data `password`, `new_password`
   * @returns {Promise<AxiosResponse>}
   */
  changePassword(data) {
    return instance.post(accountApiUrl + '/modify_password', data)
  },

  changePasswordFromToken(data, captcha) {
    return instance.post(accountApiUrl + '/reset_password', data, {
      params: {
        captcha
      }
    })
  },
  verify(data, captcha) {
    return instance.post(accountApiUrl + '/verify', data, {
      params: {
        captcha
      }
    })
  },
  login(data, captcha) {
    return oauthInstance.post(loginUrl, data, {
      params: {
        captcha
      }
    })
  },
  logout() {
    // clears the app session given correct refresh token is already in it
    return instance.post(`${loginUrl}/logout`)
  },
  getAuthorizeCodeSSOURL(provider) {
    return `/${loginUrl}/login/${provider}`
  },
  authorizeSSO(provider, code, state) {
    return oauthInstance.post(
      `${loginUrl}/${provider}/authorize?code=${code}${state ? `&state=${state}` : ''}`
    )
  },
  renewToken() {
    return instance.get(loginUrl)
  },

  getDetails() {
    return instance.get(accountApiUrl)
  },

  editDetails(data) {
    return instance.put(accountApiUrl, data)
  },

  delete() {
    return instance.delete(accountApiUrl)
  }
}
