<script setup>
import { ref, onUnmounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useProviderAccountsStore } from '@/store/provider-accounts'
import { useRouter } from 'vue-router'
import providerAccountsApi from '@/api/modules/main-server/provider_accounts'
import { useMixins } from '@/common/mixins'
import { OButton, ONotification } from '@oruga-ui/oruga-next'

const emit = defineEmits(['close'])

const router = useRouter()
const { toast } = useMixins()

const importing = ref(true)
const timeout = ref(false)
const refreshImportingTimeout = ref(null)
const stopTimeout = ref(null)

const providerAccountsStore = useProviderAccountsStore()

const { syncing } = storeToRefs(providerAccountsStore)

onUnmounted(() => {
  this.stopTimers()
})

const setSyncing = providerAccountsStore.setSyncing

function watchImport() {
  refreshImportingTimeout.value = setInterval(() => {
    providerAccountsApi.getSync(syncing.value).then(({ data }) => {
      if (data.last_sync_date !== null) {
        importSuccess()
      }
    })
  }, 60 * 1000)
}

function stopImportAfterTimeout() {
  stopTimeout.value = setTimeout(importTimeout, 60 * 60 * 1000)
}

function importTimeout() {
  timeout.value = true
  stopTimers()
}

function stopTimers() {
  clearInterval(refreshImportingTimeout.value)
  clearTimeout(stopTimeout.value)
}

function closePopup() {
  stopTimers()
  setSyncing(null)
  emit('close')
}

function getProviderAccountURL() {
  return router.resolve({
    name: 'ProviderAccount',
    params: {
      providerID: syncing.value
    }
  }).href
}

function importSuccess() {
  const url = getProviderAccountURL()
  stopTimers()
  closePopup()
  toast({
    message: `Your import is completed!<br><a href="${url}">Click here</a> to open it.`,
    variant: 'success',
    duration: 30000
  })
}

watchImport()
stopImportAfterTimeout()
</script>

<template>
  <div class="modal-card mx-auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Syncing account&hellip;</p>
      <o-button icon-left="close" class="ml-auto pl-4 is-border-radius-full" @click="closePopup" />
    </header>
    <section class="modal-card-body">
      <template v-if="!timeout && importing">
        <svg
          id="coffee"
          version="1.1"
          class="is-block mx-auto mb-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            class="steam steam1"
            d="M221.4,65.5l-4.9-6.2c-7.3-9.1-7.3-21.8,0-30.9c3.3-4.1,2.6-10.1-1.5-13.3c-4.1-3.3-10.1-2.6-13.3,1.5
            c-12.8,16.1-12.7,38.5,0,54.6l4.9,6.2c9.5,11.9,9.5,28.5,0,40.4l-5.1,6.4c-3.3,4.1-2.6,10.1,1.5,13.3c1.7,1.4,3.8,2.1,5.9,2.1
            c2.8,0,5.6-1.2,7.4-3.6l5.1-6.4C236.4,110.7,236.4,84.4,221.4,65.5z"
          />
          <path
            class="steam steam2"
            d="M274,86.9l-4.9-6.2c-7.3-9.1-7.3-21.8,0-30.9c3.3-4.1,2.6-10.1-1.5-13.3c-4.1-3.3-10.1-2.6-13.3,1.5
            c-12.8,16.1-12.7,38.5,0,54.6l4.9,6.2c9.5,11.9,9.5,28.5,0,40.4l-5.1,6.4c-3.3,4.1-2.6,10.1,1.5,13.3c1.7,1.4,3.8,2.1,5.9,2.1
            c2.8,0,5.6-1.2,7.4-3.6l5.1-6.4C289,132.1,289,105.7,274,86.9z"
          />
          <path
            class="steam steam3"
            d="M168.8,86.9l-4.9-6.2c-7.3-9.1-7.3-21.8,0-30.9c3.3-4.1,2.6-10.1-1.5-13.3c-4.1-3.3-10.1-2.6-13.3,1.5
            c-12.8,16.1-12.7,38.5,0,54.6l4.9,6.2c9.5,11.9,9.5,28.5,0,40.4l-5.1,6.4c-3.3,4.1-2.6,10.1,1.5,13.3c1.7,1.4,3.8,2.1,5.9,2.1
            c2.8,0,5.6-1.2,7.4-3.6l5.1-6.4C183.8,132.1,183.8,105.7,168.8,86.9z"
          />
          <g>
            <path
              class="coffee-cup"
              d="M489.5,209h-77.8v-20.1c0-5.2-4.3-9.5-9.5-9.5h-205c-5.2,0-9.5,4.2-9.5,9.5c0,5.2,4.3,9.5,9.5,9.5h195.5v72.8
              c0,63.9-34.1,123.3-89.2,155.7H121.2C66.1,394.5,32,335.1,32,271.2v-72.8h63.6c5.2,0,9.5-4.2,9.5-9.5c0-5.2-4.3-9.5-9.5-9.5H22.5
              c-5.2,0-9.5,4.2-9.5,9.5v82.3c0,35.9,9.6,71.1,27.9,101.7c12.3,20.7,28.3,39,47,53.9H22.5c-5.2,0-9.5,4.2-9.5,9.5
              c0,34.5,28.1,62.6,62.6,62.6h296.2c34.5,0,62.6-28.1,62.6-62.6c0-5.2-4.3-9.5-9.5-9.5h-88.1c17.2-13.8,32.1-30.4,44-49h26.8
              c50.4,0,91.4-41,91.4-91.4v-67.9C499,213.3,494.8,209,489.5,209z M414.4,445.8c-4.3,19.5-21.8,34.2-42.6,34.2H75.6
              c-20.8,0-38.3-14.6-42.6-34.2h85.7H306H414.4z M411.7,271.2V266H442v20.4c0,19-15.5,34.5-34.5,34.5h-2.2
              C409.5,304.7,411.7,288,411.7,271.2z M480,286.4c0,40-32.5,72.5-72.5,72.5h-16.2c3-6.2,5.7-12.5,8.1-19h8.1
              c29.5,0,53.5-24,53.5-53.5v-29.9c0-5.2-4.3-9.5-9.5-9.5h-39.9v-19H480V286.4z"
            />
            <path
              class="coffee-cup"
              d="M148.9,198.4c2.5,0,4.9-1,6.7-2.8c1.8-1.8,2.8-4.2,2.8-6.7c0-2.5-1-4.9-2.8-6.7c-1.8-1.8-4.2-2.8-6.7-2.8s-4.9,1-6.7,2.8
              c-1.8,1.8-2.8,4.2-2.8,6.7s1,4.9,2.8,6.7C144,197.4,146.4,198.4,148.9,198.4z"
            />
            <path
              class="coffee-cup"
              d="M321.4,379.6c28.4-28.7,44.7-68.2,44.7-108.4c0-5.2-4.3-9.5-9.5-9.5s-9.5,4.2-9.5,9.5c0,35.2-14.3,69.9-39.2,95
              c-3.7,3.7-3.7,9.7,0.1,13.4c1.9,1.8,4.3,2.8,6.7,2.8C317.1,382.4,319.5,381.4,321.4,379.6z"
            />
            <path
              class="coffee-cup"
              d="M283.5,406.2c1.6,0,3.2-0.4,4.7-1.2l0.2-0.1c4.6-2.6,6.2-8.4,3.6-12.9c-2.6-4.6-8.4-6.2-12.9-3.6l-0.2,0.1
              c-4.6,2.6-6.2,8.4-3.6,12.9C277,404.5,280.2,406.2,283.5,406.2z"
            />
          </g>
        </svg>

        <p class="mb-3">
          Your infrastructure is being imported. This process usually takes between 10-40 min
          depending on the size of your infrastructure.
        </p>
        <p class="mb-5">
          We suggest you grab a cup of coffee or tea and relax while we are taking care of
          everything&hellip;
        </p>
        <p>
          If you close this popup, go to "Provider Accounts" to find your account when it's ready.
        </p>
      </template>
      <template v-if="timeout">
        <o-notification type="warning" variant="warning">
          <p>
            Your import is taking longer than expected, please verify your access and contact us if
            you are getting any further compations.
          </p>
        </o-notification>
      </template>
    </section>
    <footer class="card-footer">
      <o-button
        variant="primary"
        class="card-footer-item is-border-top-radius-none"
        @click="closePopup"
      >
        OK
      </o-button>
    </footer>
  </div>
</template>

<style>
#coffee {
  --steam-color: #fa2ee5;
  --coffee-color: #5a40ec;
  --steam-animation: steaming 2500ms ease infinite;
  --steam-height: -10px;
  --steam-start-height: 8px;
  --steam1-delay: 100ms;
  --steam2-delay: 250ms;
  --steam3-delay: 50ms;

  height: 300px;
  width: 300px;
}

.steam {
  fill: var(--steam-color);
  transform: translateY(var(--steam-start-height));
  animation: var(--steam-animation);
}

.steam1 {
  animation-delay: var(--steam1-delay);
}

.steam2 {
  animation-delay: var(--steam2-delay);
}

.steam3 {
  animation-delay: var(--steam3-delay);
}

.coffee-cup {
  fill: var(--coffee-color);
}

@keyframes steaming {
  50% {
    transform: translateY(var(--steam-height));
  }
}
</style>
