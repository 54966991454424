<script setup>
import { onBeforeUnmount } from 'vue'
import { storeToRefs } from 'pinia'
import EventBus from '@/common/eventBus'
import accountApi from '@/api/modules/main-server/account'
import { useAccountStore } from '@/store/account'
import { useRouter, RouterLink } from 'vue-router'

const router = useRouter()

const accountStore = useAccountStore()

const { isStripeCustomer, getUsername, getUsernameFirstLetter } = storeToRefs(accountStore)

onBeforeUnmount(() => {
  EventBus.remove('upgradePricingPlan')
})

function upgradePlan(newTab) {
  if (isStripeCustomer.value) {
    EventBus.dispatch('startGlobalLoading')
    accountApi.stripePortal().then((res) => {
      EventBus.dispatch('stopGlobalLoading')
      location.href = res.data.url
    })
  } else {
    const route = { name: 'UpgradePricingPlan' }
    if (newTab) {
      const { href } = router.resolve(route)
      window.open(href, '_blank')
    } else {
      router.push(route).catch(() => {})
    }
  }
}

EventBus.on('upgradePricingPlan', upgradePlan)
</script>

<template>
  <RouterLink
    :to="{ name: 'Account' }"
    class="mb-3 is-flex is-align-items-center is-clickable has-text-white is-capitalized p-3 user-account-link"
    :class="{ 'user-account-link--active': $route.path.startsWith('/account') }"
  >
    <span
      class="is-flex is-align-items-center is-justify-content-center is-border-radius-full has-text-weight-bold mr-3 first-letter"
    >
      {{ getUsernameFirstLetter }}
    </span>

    {{ getUsername }}
  </RouterLink>
</template>

<style scoped>
.user-account-link {
  border-radius: 4px;
  width: calc(var(--sidebar-width) - 1.5rem);
}
.user-account-link:not(.user-account-link--active):hover {
  background: #8877e8;
}
.user-account-link--active {
  background: var(--special-purple-1);
}
.first-letter {
  background-color: var(--primary);
  border: 1px solid transparent;
  height: 40px;
  width: 40px;
  transition: border-color 0.2s ease;
}
.user-account-link--active .first-letter {
  border-color: var(--purple-end);
}
:deep(.dropdown-menu) {
  background-color: var(--secondary-dark-black);
  right: auto !important;
  top: auto;
  bottom: 0.75rem;
  left: calc(var(--sidebar-width) + 1px) !important;
  position: fixed;
  z-index: 31;
}
</style>
