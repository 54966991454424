import membersApi from '@/api/organizations/members'
import { defineStore } from 'pinia'

export const useMemberStore = defineStore({
  id: 'member',

  state: () => ({
    member: null,
    members: []
  }),

  actions: {
    getMembers(organizationID, offset, limit, filters) {
      return new Promise((resolve, reject) => {
        membersApi.getMembers(organizationID, offset, limit, filters).then(
          ({ data }) => {
            this.members = data
            resolve(data)
          },
          (err) => reject(err)
        )
      })
    },

    getMember(organizationID, memberID) {
      return new Promise((resolve, reject) => {
        membersApi.getMember(organizationID, memberID).then(
          ({ data }) => {
            this.member = data
            resolve(data)
          },
          (err) => reject(err)
        )
      })
    },

    /**
     * Modification of a user membership.
     * @param {Number} organizationID The organization ID.
     * @param {Number} memberID The member ID.
     * @param {Object} data `role`
     * @returns {Promise<AxiosResponse>}
     */
    editMember(organizationID, memberID, data) {
      return membersApi.editMember(organizationID, memberID, data)
    },

    deleteMember(organizationID, memberID) {
      return new Promise((resolve, reject) => {
        membersApi.deleteMember(organizationID, memberID).then(
          ({ data }) => {
            resolve(data)
          },
          (err) => reject(err)
        )
      })
    }
  }
})
