import providerAccountsApi from '@/api/organizations/provider-accounts'
import { defineStore } from 'pinia'
import instance from '@/api/index.js'
import moment from 'moment'
import mergeDiagrams from '@/common/diagram-options/methods/merge.js'

const initialState = {
  providerAccounts: [],
  total: 0,
  providerAccount: null,

  synchronizations: [],
  synchronizationDiagram: null,
  synchronizationThumbnail: null,

  loading: {
    synchronizations: false,
    synchronizationDiagram: false,
    synchronizationThumbnail: false,
    providerAccounts: {}
  }
}

const emptyDiagram = { nodes: [], edges: [], groups: [] }

export const useProviderAccountsStore = defineStore({
  id: 'provider-account',

  state: () => initialState,

  getters: {
    lastSynchronizationDate: ({ synchronizations }) => synchronizations[0]?.imported
  },

  actions: {
    /**
     * Get a list of provider accounts in this organization.
     * @param {Number} organizationID The organization ID.
     * @param {Object} filters `name`, `provider_name`, `enabled`, `created_since`, `created_until`, `offset`, `limit`
     * @returns {Promise<Array<Object>>}
     */
    getProviderAccounts(organizationID, filters = {}) {
      if ('provider_name' in filters) {
        this.loading.providerAccounts[filters.provider_name] = true
      }
      return new Promise((resolve, reject) => {
        providerAccountsApi.getProviderAccounts(organizationID, filters).then(
          (res) => {
            if ('provider_name' in filters) {
              this.loading.providerAccounts[filters.provider_name] = false
            }

            const azureAccounts = res.data.filter((acc) => acc.provider_name === 'azure')
            azureAccounts.forEach((acc) => {
              // this.getAzureSubscriptions(organizationID, acc)
            })

            this.providerAccounts = res.data
            this.total = Number(res.headers['content-range'].split('/')[1])
            resolve(res.data)
          },
          (err) => reject(err)
        )
      })
    },

    getAzureSubscriptions(organizationID, account) {
      return new Promise((resolve) => {
        const hereAccount = this.providerAccounts.find((acc) => acc.id === account.id)
        if (hereAccount && 'subscriptions' in hereAccount) {
          resolve(hereAccount.subscriptions)
          return
        }

        providerAccountsApi
          .getAzureSubscriptions(organizationID, account.id)
          .then(({ data: subscriptions }) => {
            const accountIndex = this.providerAccounts.findIndex((acc) => acc.id === account.id)
            this.providerAccounts[accountIndex] = {
              ...account,
              subscriptions
            }
            resolve(subscriptions)
          })
      })
    },

    /**
     * Create a new provider account in this organization.
     * @param {Number} organizationID The organization ID.
     * @param {Object} data `name`, `enabled`, `provider_name`, `authentication_data`
     * @returns {Promise<AxiosResponse>}
     */
    createProviderAccount(organizationID, data) {
      this.providerAccount = null

      return new Promise((resolve, reject) => {
        providerAccountsApi.createProviderAccount(organizationID, data).then(
          (res) => {
            this.providerAccount = res.data
            resolve(res)
          },
          (err) => reject(err)
        )
      })
    },

    /**
     * Edit a provider account in this organization.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @param {Object} data `name`, `enabled`, `provider_name`, `authentication_data`
     * @returns {Promise<AxiosResponse>}
     */
    editProviderAccount(organizationID, accountID, data) {
      return new Promise((resolve, reject) => {
        providerAccountsApi.editProviderAccount(organizationID, accountID, data).then(
          (res) => resolve(res),
          (err) => reject(err)
        )
      })
    },

    setProviderAccount(account) {
      this.providerAccount = account
    },

    /**
     * Get a provider account in this organization.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @returns {Promise<AxiosResponse>}
     */
    getProviderAccount(organizationID, accountID) {
      return new Promise((resolve, reject) => {
        providerAccountsApi.getProviderAccount(organizationID, accountID).then(
          ({ data }) => {
            this.providerAccount = data
            resolve(data)
          },
          (err) => reject(err)
        )
      })
    },

    /**
     * Delete a provider account in this organization.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @returns {Promise<AxiosResponse>}
     */
    deleteProviderAccount(organizationID, accountID) {
      return new Promise((resolve, reject) => {
        providerAccountsApi.deleteProviderAccount(organizationID, accountID).then(
          () => {
            resolve()
          },
          (err) => reject(err)
        )
      })
    },

    /**
     * Verify a provider account in this organization.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @returns {Promise<AxiosResponse>}
     */
    verifyProviderAccount(organizationID, accountID) {
      return new Promise((resolve, reject) => {
        providerAccountsApi.verifyProviderAccount(organizationID, accountID).then(
          (res) => {
            resolve(res.data)
          },
          (err) => reject(err)
        )
      })
    },

    /**
     * Get synchronizations for provider account.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @returns {Promise<Array<Object>>}
     */
    getSynchronizations(organizationID, accountID) {
      this.synchronizations = []
      this.loading.synchronizations = true

      return new Promise((resolve) => {
        providerAccountsApi.getSynchronizations(organizationID, accountID).then(({ data }) => {
          this.synchronizations = data
          this.loading.synchronizations = false
          resolve(data)
        })
      })
    },

    /**
     * Get the latest synchronization from a provider account.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @returns {Promise<Object>}
     */
    getLastSynchronization(organizationID, accountID) {
      this.synchronizations = []
      this.loading.synchronizations = true

      return new Promise((resolve) => {
        providerAccountsApi.getLastSynchronization(organizationID, accountID).then(({ data }) => {
          const sync = data[0]
          this.synchronizations = data
          this.loading.synchronizations = false
          resolve(sync)
        })
      })
    },

    /**
     * Update the synchronizations.
     * @param {Array<Object>} synchronizations
     */
    setSynchronizations(synchronizations) {
      this.synchronizations = synchronizations
    },

    /**
     * Get a synchronization entry.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @param {Number} synchronizationID The synchronization ID.
     * @returns {Promise<Object>}
     */
    getSynchronization(organizationID, accountID, synchronizationID) {
      return new Promise((resolve, reject) => {
        providerAccountsApi.getSynchronization(organizationID, accountID, synchronizationID).then(
          ({ data }) => {
            resolve(data)
          },
          () => reject(new Error('Could not get synchronization.'))
        )
      })
    },

    /**
     * Get the diagram for a synchronization entry.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @param {Number} synchronizationID The synchronization ID.
     * @returns {Promise<String>}
     */
    getSynchronizationDiagram(organizationID, accountID, synchronizationID) {
      this.synchronizationDiagram = null
      this.loading.synchronizationDiagram = true

      return new Promise((resolve, reject) => {
        providerAccountsApi
          .getSynchronizationDiagram(organizationID, accountID, synchronizationID)
          .then(
            ({ data }) => {
              let { diagram } = data
              if (!diagram) {
                diagram = JSON.stringify(emptyDiagram)
              }
              this.synchronizationDiagram = diagram
              this.loading.synchronizationDiagram = false
              resolve(diagram)
            },
            () => reject(new Error('Could not get synchronization diagram.'))
          )
      })
    },

    /**
     * Set the synchronization diagram.
     * @param {Object} synchronizationDiagram The diagram data.
     */
    setSynchronizationDiagram(synchronizationDiagram) {
      this.synchronizationDiagram = synchronizationDiagram
    },

    /**
     * Get the thumbnail for a synchronization entry.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @param {Number} synchronizationID The synchronization ID.
     * @returns {Promise<Object>}
     */
    getSynchronizationThumbnail(organizationID, accountID, synchronizationID) {
      this.synchronizationThumbnail = null
      this.loading.synchronizationThumbnail = true

      return new Promise((resolve, reject) => {
        providerAccountsApi
          .getSynchronizationThumbnail(organizationID, accountID, synchronizationID)
          .then(
            ({ data }) => {
              this.synchronizationThumbnail = data.thumbnail
              this.loading.synchronizationThumbnail = false
              resolve(data.thumbnail)
            },
            () => reject(new Error('Could not get synchronization thumbnail.'))
          )
      })
    },

    setSynchronizationThumbnail(synchronizationThumbnail) {
      this.synchronizationThumbnail = synchronizationThumbnail
    },

    /**
     * Edit a synchronization.
     * @param {Number} organizationID
     * @param {Number} accountID
     * @param {Number} synchronizationID
     * @param {{ thumbnail: String, diagram: String }} payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    editSynchronization(organizationID, accountID, synchronizationID, payload) {
      return providerAccountsApi.editSynchronization(
        organizationID,
        accountID,
        synchronizationID,
        payload
      )
    },

    getNumberOfAssets(organizationID, accountID) {
      return new Promise((resolve) => {
        this.getLastSynchronization(organizationID, accountID).then((sync) => {
          this.getSynchronizationDiagram(organizationID, accountID, sync.id).then((diagram) => {
            const parsed = JSON.parse(diagram)
            const items = [...parsed.nodes, ...parsed.groups]
            const numberOfAssets = items.filter(
              (item) => item.type !== 'TextBox' && item.type !== 'text-box'
            ).length
            resolve(numberOfAssets)
          })
        })
      })
    },

    /**
     * Get cost and usage
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @param {Object} filters `granularity`, `filters`, `group_by`, `start_date`, `end_date`
     * @param {Object} rules
     * @returns {Promise<AxiosResponse>}
     */
    getCost(organizationID, accountID, filters = {}, rules = {}) {
      return providerAccountsApi.getCost(organizationID, accountID, filters, rules)
    },

    /**
     * Get regions.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @param {String} startDate The start date.
     * @param {String} endDate The end date.
     * @param {Object} filters The filters.
     * @returns {Promise<AxiosResponse>}
     */
    getRegions(organizationID, accountID, startDate, endDate, filters) {
      return providerAccountsApi.getRegions(organizationID, accountID, startDate, endDate, filters)
    },

    /**
     * Get available services for provider account.
     * @param {Number} organizationID The ID of the organization.
     * @param {Number} accountID The ID of the provider account.
     * @param {String} startDate The start date.
     * @param {String} endDate The end date.
     * @param {Object} filters The filters.
     * @returns {Promise<AxiosResponse<any>>}
     */
    getServices(organizationID, accountID, startDate, endDate, filters) {
      return providerAccountsApi.getServices(organizationID, accountID, startDate, endDate, filters)
    },

    /**
     * Get available resources names for provider account.
     * @param {Number} organizationID The ID of the organization.
     * @param {Number} accountID The ID of the provider account.
     * @param {String} startDate The start date.
     * @param {String} endDate The end date.
     * @param {Object} filters The filters.
     * @returns {Promise<AxiosResponse<any>>}
     */
    getResources(organizationID, accountID, startDate, endDate, filters) {
      return providerAccountsApi.getResources(
        organizationID,
        accountID,
        startDate,
        endDate,
        filters
      )
    },

    /**
     * Get cost forecast.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @param {Object} filters `granularity`, `filters`, `group_by`, `start_date`, `end_date`
     * @returns {Promise<AxiosResponse>}
     */
    getCostForecast(organizationID, accountID, filters = {}) {
      return providerAccountsApi.getCostForecast(organizationID, accountID, filters)
    },

    /**
     * Get tags.
     * @param {Number} organizationID The organization ID.
     * @param {Number} accountID The provider account ID.
     * @param {String} startDate Start date.
     * @param {String} endDate End date.
     * @param {Object} filters `granularity`, `filters`, `group_by`, `start_date`, `end_date`
     * @returns {Promise<AxiosResponse>}
     */
    getTags(organizationID, accountID, startDate, endDate, filters) {
      return providerAccountsApi.getTags(organizationID, accountID, startDate, endDate, filters)
    },

    search(organizationID, accountID, resourceID) {
      return providerAccountsApi.search(organizationID, accountID, resourceID)
    },

    /**
     * Get Azure resource details.
     * @param {Number} organizationID The ID of the organization.
     * @param {Number} accountID The ID of the provider account.
     * @param {String} resourceID Resource ID from the provider.
     * @returns {Promise<AxiosResponse<any>>}
     */
    getAzureDetails(organizationID, accountID, resourceID) {
      return providerAccountsApi.getAzureDetails(organizationID, accountID, resourceID)
    },

    /**
     * Get dimension values.
     * @param {Number} organizationID The ID of the organization.
     * @param {Number} accountID The ID of the provider account.
     * @param {String} provider The provider (`aws`, `gcp`, etc.).
     * @param {String} dimension The dimension to get.
     * @returns {Promise<AxiosResponse<any>>}
     */
    getDimensionValues(organizationID, accountID, provider, dimension) {
      return providerAccountsApi.getDimensionValues(organizationID, accountID, provider, dimension)
    },

    loadDemoDetails(skipThumbnail = false) {
      return new Promise((resolve) => {
        instance.get('/example-infrastructures/aws.json').then(({ data }) => {
          this.setProviderAccount({
            id: 'demo',
            name: 'Demo Account',
            autosync: false
          })
          this.setSynchronizationDiagram(data.diagram)

          if (!skipThumbnail) {
            this.setSynchronizationThumbnail(data.thumbnail)
          }

          this.setSynchronizations([
            {
              imported: moment().format('YYYY-MM-DD')
            }
          ])

          resolve()
        })
      })
    },

    getProviderDiagram(organizationID, provider) {
      return new Promise((bigResolve, bigReject) => {
        providerAccountsApi
          .getProviderAccounts(organizationID, { provider_name: provider })
          .then(({ data: accounts }) => {
            if (!accounts.length) {
              bigReject(`No matching accounts for provider ${provider}.`)
              return
            }

            const accountsPromises = accounts.map(({ id: accountID }) => {
              return new Promise((resolveAccount) => {
                providerAccountsApi
                  .getLastSynchronization(organizationID, accountID)
                  .then(({ data: synchronizations }) => {
                    const [lastSynchronization] = synchronizations
                    // No last synchronization, resolve empty data.
                    if (!lastSynchronization) {
                      resolveAccount(emptyDiagram)
                    } else {
                      providerAccountsApi
                        .getSynchronizationDiagram(
                          organizationID,
                          accountID,
                          lastSynchronization.id
                        )
                        .then(({ data: { diagram } }) => {
                          if (!diagram) {
                            return resolveAccount(emptyDiagram)
                          }
                          let transformedDiagram = diagram
                          const jsonDiagram = JSON.parse(diagram)
                          const { nodes, edges, groups } = jsonDiagram
                          const allElements = [...nodes, ...edges, ...groups]
                          // Gather all the IDs of the elements...
                          const allIDs = allElements.map(({ id }) => id)

                          // Then replace them everywhere.
                          for (const id of allIDs) {
                            const transformedID = id + '-' + accountID
                            transformedDiagram = transformedDiagram.replaceAll(id, transformedID)
                          }

                          resolveAccount(JSON.parse(transformedDiagram))
                        })
                    }
                  })
              })
            })

            Promise.allSettled(accountsPromises).then((results) => {
              const diagrams = results.map(({ value }) => value)
              bigResolve(mergeDiagrams(diagrams))
            })
          })
      })
    },

    getRightsizingRecommendations(organizationID, accountID) {
      return providerAccountsApi.getRightsizingRecommendations(organizationID, accountID)
    },

    getDetails(organizationID, accountID, resourceID, provider) {
      return providerAccountsApi.getDetails(organizationID, accountID, resourceID, provider)
    }
  }
})
