import instance from '@/api'
import { organizationsApiUrl } from '.'
import { useDashboardStore } from '@/store/dashboard.js'
import { findAWSTypeName } from '@/common/aws-type-names.js'
import moment from 'moment'

const serializedFilters = (filters) => {
  const result = {}
  for (const filter in filters) {
    const value = filters[filter]
    result[filter] = typeof value !== 'string' ? JSON.stringify(value) : value
  }

  if ('start_date' in filters && 'end_date' in filters) {
    const start = moment(filters.start_date)
    const end = moment(filters.end_date)
    const days = moment.duration(end.diff(start)).as('days')

    result.granularity = days > 30 ? 'monthly' : 'daily'
  }

  return result
}

export default {
  /**
   * Get a list of provider accounts in this organization.
   * @param {Number} organizationID The organization ID.
   * @param {Object} filters `name`, `provider_name`, `enabled`, `created_since`, `created_until`, `offset`, `limit`
   * @returns {Promise<AxiosResponse>}
   */
  getProviderAccounts(organizationID, filters = {}) {
    return instance.get(organizationsApiUrl + '/' + organizationID + '/provider_accounts', {
      params: filters
    })
  },

  /**
   * Create a new provider account in this organization.
   * @param {Number} organizationID The organization ID.
   * @param {Object} data `name`, `enabled`, `provider_name`, `authentication_data`
   * @returns {Promise<AxiosResponse>}
   */
  createProviderAccount(organizationID, data) {
    return instance.post(organizationsApiUrl + '/' + organizationID + '/provider_accounts', data)
  },

  /**
   * Get a provider account in this organization.
   * @param {Number} organizationID The organization ID.
   * @param {Number} projectID The provider account ID.
   * @returns {Promise<AxiosResponse>}
   */
  getProviderAccount(organizationID, projectID) {
    return instance.get(
      organizationsApiUrl + '/' + organizationID + '/provider_accounts/' + projectID
    )
  },

  /**
   * Edit a provider account in this organization.
   * @param {Number} organizationID The organization ID.
   * @param {Number} accountID The provider account ID.
   * @param {Object} data `name`, `enabled`, `provider_name`, `authentication_data`
   * @returns {Promise<AxiosResponse>}
   */
  editProviderAccount(organizationID, accountID, data) {
    return instance.put(
      organizationsApiUrl + '/' + organizationID + '/provider_accounts/' + accountID,
      data
    )
  },

  /**
   * Delete a provider account in this organization.
   * @param {Number} organizationID The organization ID.
   * @param {Number} accountID The provider account ID.
   * @returns {Promise<AxiosResponse>}
   */
  deleteProviderAccount(organizationID, accountID) {
    return instance.delete(
      organizationsApiUrl + '/' + organizationID + '/provider_accounts/' + accountID
    )
  },

  /**
   * Verify a provider account in this organization.
   * @param {Number} organizationID The organization ID.
   * @param {Number} accountID The provider account ID.
   * @returns {Promise<AxiosResponse>}
   */
  verifyProviderAccount(organizationID, accountID) {
    return instance.post(
      organizationsApiUrl + '/' + organizationID + '/provider_accounts/' + accountID + '/verify'
    )
  },

  /**
   * Get synchronizations for provider account.
   * @param {Number} organizationID The organization ID.
   * @param {Number} accountID The provider account ID.
   * @returns {Promise<AxiosResponse>}
   */
  getSynchronizations(organizationID, accountID) {
    return instance.get(
      organizationsApiUrl +
        '/' +
        organizationID +
        '/provider_accounts/' +
        accountID +
        '/synchronizations',
      {
        params: {
          sort_by: 'desc(id)'
        }
      }
    )
  },

  /**
   * Get last synchronization for provider account.
   * @param {Number} organizationID
   * @param {Number} accountID
   * @returns {Promise<AxiosResponse<any>>}
   */
  getLastSynchronization(organizationID, accountID) {
    return instance.get(
      organizationsApiUrl +
        '/' +
        organizationID +
        '/provider_accounts/' +
        accountID +
        '/synchronizations',
      {
        params: {
          limit: 1,
          sort_by: 'desc(id)'
        }
      }
    )
  },

  /**
   * Get a synchronization entry.
   * @param {Number} organizationID
   * @param {Number} accountID
   * @param {Number} synchronizationID
   * @returns {Promise<AxiosResponse<any>>}
   */
  getSynchronization(organizationID, accountID, synchronizationID) {
    return instance.get(
      organizationsApiUrl +
        '/' +
        organizationID +
        '/provider_accounts/' +
        accountID +
        '/synchronizations/' +
        synchronizationID
    )
  },

  /**
   * Get the diagram for a synchronization.
   * @param {Number} organizationID
   * @param {Number} accountID
   * @param {Number} synchronizationID
   * @returns {Promise<AxiosResponse<any>>}
   */
  getSynchronizationDiagram(organizationID, accountID, synchronizationID) {
    return instance.get(
      organizationsApiUrl +
        '/' +
        organizationID +
        '/provider_accounts/' +
        accountID +
        '/synchronizations/' +
        synchronizationID +
        '/diagram'
    )
  },

  /**
   * Get the thumbnail for a synchronization.
   * @param {Number} organizationID
   * @param {Number} accountID
   * @param {Number} synchronizationID
   * @returns {Promise<AxiosResponse<any>>}
   */
  getSynchronizationThumbnail(organizationID, accountID, synchronizationID) {
    return instance.get(
      organizationsApiUrl +
        '/' +
        organizationID +
        '/provider_accounts/' +
        accountID +
        '/synchronizations/' +
        synchronizationID +
        '/thumbnail'
    )
  },

  /**
   * Edit a synchronization.
   * @param {Number} organizationID
   * @param {Number} accountID
   * @param {Number} synchronizationID
   * @param {{ thumbnail: String, diagram: String }} payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  editSynchronization(organizationID, accountID, synchronizationID, payload) {
    return instance.put(
      organizationsApiUrl +
        '/' +
        organizationID +
        '/provider_accounts/' +
        accountID +
        '/synchronizations/' +
        synchronizationID,
      payload
    )
  },

  /**
   * Get cost and usage
   * @param {Number} organizationID The organization ID.
   * @param {Number} accountID The provider account ID.
   * @param {Object} filters `granularity`, `filters`, `group_by`, `start_date`, `end_date`
   * @param {Object} rules
   * @returns {Promise<AxiosResponse>}
   */
  getCost(organizationID, accountID, filters, rules) {
    return instance.post(
      organizationsApiUrl + '/' + organizationID + '/provider_accounts/' + accountID + '/costs',
      rules
        ? {
            name: '',
            rules
          }
        : null,
      {
        params: {
          ...serializedFilters(filters)
        }
      }
    )
  },

  /**
   * Get regions.
   * @param {Number} organizationID The organization ID.
   * @param {Number} accountID The provider account ID.
   * @param {String} startDate The start date.
   * @param {String} endDate The end date.
   * @param {Object} rules The filters.
   * @returns {Promise<AxiosResponse>}
   */
  getRegions(organizationID, accountID, startDate, endDate, rules = {}) {
    return instance.post(
      organizationsApiUrl + '/' + organizationID + '/provider_accounts/' + accountID + '/regions',
      {
        name: '',
        rules
      },
      {
        params: {
          start_date: startDate,
          end_date: endDate
        }
      }
    )
  },

  /**
   * Get available services for provider account.
   * @param {Number} organizationID The ID of the organization.
   * @param {Number} accountID The ID of the provider account.
   * @param {String} startDate The start date.
   * @param {String} endDate The end date.
   * @param {Object} rules The filters.
   * @returns {Promise<AxiosResponse<any>>}
   */
  getServices(organizationID, accountID, startDate, endDate, rules) {
    return instance.post(
      organizationsApiUrl + '/' + organizationID + '/provider_accounts/' + accountID + '/services',
      {
        name: '',
        rules
      },
      {
        params: {
          start_date: startDate,
          end_date: endDate
        }
      }
    )
  },

  /**
   * Get available resources IDs for provider account.
   * @param {Number} organizationID The ID of the organization.
   * @param {Number} accountID The ID of the provider account.
   * @param {String} startDate The start date.
   * @param {String} endDate The end date.
   * @param {Object} rules The filters.
   * @returns {Promise<AxiosResponse<any>>}
   */
  getResources(organizationID, accountID, startDate, endDate, rules) {
    return instance.post(
      organizationsApiUrl + '/' + organizationID + '/provider_accounts/' + accountID + '/resources',
      {
        name: '',
        rules
      },
      {
        params: {
          start_date: startDate,
          end_date: endDate
        }
      }
    )
  },

  /**
   * Get cost forecast
   * @param {Number} organizationID The organization ID.
   * @param {Number} accountID The provider account ID.
   * @param {Object} filters `granularity`, `filters`, `group_by`, `start_date`, `end_date`
   * @returns {Promise<AxiosResponse>}
   */
  getCostForecast(organizationID, accountID, filters) {
    const dashboardStore = useDashboardStore()

    return instance.post(
      organizationsApiUrl +
        '/' +
        organizationID +
        '/provider_accounts/' +
        accountID +
        '/costs/forecast',
      {
        name: 'cost forecast',
        rules: dashboardStore.getAPIFilters
      },
      {
        params: {
          ...serializedFilters(filters)
        }
      }
    )
  },

  /**
   * Get tags.
   * @param {Number} organizationID The organization ID.
   * @param {Number} accountID The provider account ID.
   * @param {String} startDate Start date.
   * @param {String} endDate End date.
   * @param {Object} filters `granularity`, `filters`, `group_by`, `start_date`, `end_date`
   * @returns {Promise<AxiosResponse>}
   */
  getTags(organizationID, accountID, startDate, endDate, filters) {
    const serializedFilters = {}
    for (const filter in filters) {
      const value = filters[filter]
      serializedFilters[filter] = typeof value !== 'string' ? JSON.stringify(value) : value
    }
    return instance.post(
      organizationsApiUrl +
        '/' +
        organizationID +
        '/provider_accounts/' +
        accountID +
        '/aws/cost_explorer/get_tags',
      null,
      {
        params: {
          ...serializedFilters,
          start_date: startDate,
          end_date: endDate
        }
      }
    )
  },

  /**
   * Search resource details.
   * @param {Number} organizationID The ID of the organization.
   * @param {Number} accountID The ID of the provider account.
   * @param {String} resourceID Resource ID from the provider.
   * @returns {Promise<AxiosResponse<any>>}
   */
  search(organizationID, accountID, resourceID) {
    return instance.post(
      organizationsApiUrl +
        '/' +
        organizationID +
        '/provider_accounts/' +
        accountID +
        '/aws/search',
      {
        query_string: resourceID
      }
    )
  },

  /**
   * Get Azure resource details.
   * @param {Number} organizationID The ID of the organization.
   * @param {Number} accountID The ID of the provider account.
   * @param {String} resourceID Resource ID from the provider.
   * @returns {Promise<AxiosResponse<any>>}
   */
  getAzureDetails(organizationID, accountID, resourceID) {
    return instance.post(
      organizationsApiUrl +
        '/' +
        organizationID +
        '/provider_accounts/' +
        accountID +
        '/azure/details',
      {
        resource_id: resourceID
      }
    )
  },

  /**
   * Get dimension values.
   * @param {Number} organizationID The ID of the organization.
   * @param {Number} accountID The ID of the provider account.
   * @param {String} provider The provider (`aws`, `gcp`, etc.).
   * @param {String} dimension The dimension to get.
   * @returns {Promise<AxiosResponse<any>>}
   */
  getDimensionValues(organizationID, accountID, provider, dimension) {
    let dimensionsURL

    switch (provider) {
      case 'aws':
        dimensionsURL = '/aws/cost_explorer/get_dimension_values'
        break
      case 'gcp':
        dimensionsURL = '/gcp/query/dimension_values'
        break
    }

    return instance.post(
      organizationsApiUrl +
        '/' +
        organizationID +
        '/provider_accounts/' +
        accountID +
        dimensionsURL,
      {
        dimension
      }
    )
  },

  getRightsizingRecommendations(organizationID, account) {
    const { id, provider_name } = account
    let url, payload, query
    switch (provider_name) {
      case 'aws':
        url = '/aws/cost_explorer/get_rightsizing_recommendation'
        payload = {
          service: 'AmazonEC2',
          configuration: {},
          filters: {}
        }
        break
      case 'gcp':
        url = `/gcp/recommendations`
        payload = null
        query = {
          category: 'cost'
        }
        break
    }

    return instance.post(
      organizationsApiUrl + '/' + organizationID + '/provider_accounts/' + id + url,
      payload,
      {
        params: query
      }
    )
  },

  // Azure

  /**
   * Get Azure account subscriptions.
   * @param {Number} organizationID The ID of the organization.
   * @param {Number} accountID The ID of the provider account.
   * @returns {Promise<AxiosResponse<any>>}
   */
  getAzureSubscriptions(organizationID, accountID) {
    return instance.post(
      organizationsApiUrl +
        '/' +
        organizationID +
        '/provider_accounts/' +
        accountID +
        '/azure/general/subscriptions'
    )
  },

  getDetails(organizationID, accountID, resourceID, provider) {
    let payload = {}

    if (provider === 'azure') {
      payload.resource_id = resourceID
    } else if (provider === 'aws') {
      const { type_name, identifier } = findAWSTypeName(resourceID)
      if (type_name) {
        payload = {
          type_name,
          identifier
        }
      }
    }

    return instance.post(
      organizationsApiUrl +
      '/' +
      organizationID +
      '/provider_accounts/' +
      accountID +
      '/' +
      provider +
      '/details',
      payload
    )
  }
}
