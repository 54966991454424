import instance from '@/api'
import mainServerUrl from '@/api/modules/main-server'

export const organizationsApiUrl = mainServerUrl + 'organizations'

export default {
  /**
   * Get a list of organizations.
   * @param {Number|undefined} offset Minimum 0.
   * @param {Number|undefined} limit Minimum 1 Maximum 100.
   * @param {Object} filters `name`, `created_since`, `created_until`.
   * @returns {Promise<AxiosResponse>}
   */
  getPage(offset, limit, filters) {
    return instance.get(organizationsApiUrl, {
      params: {
        ...filters,
        offset,
        limit
      }
    })
  },

  /**
   * Get details of an organization.
   * @param {Number} organizationID The organization ID.
   * @returns {Promise<AxiosResponse>}
   */
  get(organizationID) {
    return instance.get(organizationsApiUrl + '/' + organizationID)
  },

  /**
   * Create an organization.
   * @param {Object} data `name`.
   * @returns {Promise<AxiosResponse>}
   */
  create(data) {
    return instance.post(organizationsApiUrl, data)
  },

  /**
   * Modification of an organization.
   * @param {Number} organizationID The organization ID.
   * @param {Object} data `name`.
   * @returns {Promise<AxiosResponse>}
   */
  edit(organizationID, data) {
    return instance.put(organizationsApiUrl + '/' + organizationID, data)
  },

  /**
   * Delete an organization. Their account and data will be entirely erased.
   * @param {Number} organizationID The organization ID.
   * @returns {Promise<AxiosResponse>}
   */
  delete(organizationID) {
    return instance.delete(organizationsApiUrl + '/' + organizationID)
  },

  getStripePortal(organizationID) {
    return instance.get(organizationsApiUrl + '/' + organizationID + '/stripe/portal')
  }
}
