import instance from '@/api'
import { organizationsApiUrl } from '.'

export default {
  /**
   * Get a list of members.
   * @param {Number} organizationID The organization ID.
   * @param {Number|undefined} offset Minimum 0.
   * @param {Number|undefined} limit Minimum 1 Maximum 100.
   * @param {Object} filters `name`, `email`, `created_since`, `created_until`, `last_activity_since`, `last_activity_until`, `role`, `joined_since`, `joined_until`
   * @returns {Promise<AxiosResponse>}
   */
  getMembers(organizationID, offset, limit, filters) {
    return instance.get(organizationsApiUrl + '/' + organizationID + '/members', {
      params: {
        ...filters,
        offset,
        limit
      }
    })
  },

  /**
   * Get details of a member.
   * @param {Number} organizationID The organization ID.
   * @param {Number} memberID The member ID.
   * @returns {Promise<AxiosResponse>}
   */
  getMember(organizationID, memberID) {
    return instance.get(organizationsApiUrl + '/' + organizationID + '/members/' + memberID)
  },

  /**
   * Modification of a user membership.
   * @param {Number} organizationID The organization ID.
   * @param {Number} memberID The member ID.
   * @param {Object} data `role`
   * @returns {Promise<AxiosResponse>}
   */
  editMember(organizationID, memberID, data) {
    return instance.put(organizationsApiUrl + '/' + organizationID + '/members/' + memberID, data)
  },

  /**
   * Delete a user membership.
   * @param {Number} organizationID The organization ID.
   * @param {Number} memberID The member ID.
   * @returns {Promise<AxiosResponse>}
   */
  deleteMember(organizationID, memberID) {
    return instance.delete(organizationsApiUrl + '/' + organizationID + '/members/' + memberID)
  }
}
