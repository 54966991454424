<script setup>
import eventBus from '@/common/eventBus'
import { OIcon } from '@oruga-ui/oruga-next'
import { useOrganizationMembership } from '@/common/organization-membership.js'

const helpMenuActive = defineModel('active')

const props = defineProps({
  canHaveKeyboardShortcuts: {
    type: Boolean,
    required: true
  }
})

const { goTo } = useOrganizationMembership(false)

function openCalendly() {
  eventBus.dispatch('openCalendly')
}

function openKeyboardShortcuts() {
  eventBus.dispatch('openKeyboardShortcuts')
}

function startOnboarding() {
  helpMenuActive.value = false
  goTo('CostDashboard', { query: { onboard: true } })
}
</script>

<template>
  <div>
    <a
      class="dropdown-item is-flex is-align-items-center is-whitespace-normal"
      @click="startOnboarding"
    >
      <o-icon icon="sign-direction" class="mr-2 has-text-white" />
      Product tour
    </a>
    <a
      class="dropdown-item is-flex is-align-items-center is-whitespace-normal"
      href="https://doc.holori.com"
      aria-role="listitem"
      target="_blank"
    >
      <o-icon icon="book-open-variant" class="mr-2 has-text-white" />
      Documentation
    </a>
    <a
      v-if="props.canHaveKeyboardShortcuts"
      v-keyboard-shortcut="'Ctrl+/'"
      class="dropdown-item is-flex is-align-items-center is-whitespace-normal"
      aria-role="listitem"
      @click="openKeyboardShortcuts"
    >
      <o-icon icon="keyboard" class="mr-2 has-text-white" />
      Keyboard Shortcuts
    </a>
    <a
      class="dropdown-item is-flex is-align-items-center is-whitespace-normal"
      href="https://www.youtube.com/@holori_cloud"
      target="_blank"
      aria-role="listitem"
    >
      <o-icon icon="youtube" class="mr-2 has-text-white" />
      YouTube tutorials
    </a>
    <a
      class="dropdown-item is-flex is-align-items-center is-whitespace-normal"
      aria-role="listitem"
      @click="openCalendly"
    >
      <o-icon icon="bulletin-board" class="mr-2 has-text-white" />
      Dedicated onboarding
    </a>
    <a
      class="dropdown-item is-flex is-align-items-center is-whitespace-normal"
      href="https://holori.com/contact"
      target="_blank"
      aria-role="listitem"
    >
      <o-icon icon="chat-question" class="mr-2 has-text-white" />
      Contact Us
    </a>
  </div>
</template>

<style scoped>
.dropdown-item {
  color: #fff;
}
.dropdown-item:hover {
  background-color: #8877e8;
  color: #fff;
}
</style>
