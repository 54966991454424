import instance from '@/api'
import { organizationsApiUrl } from '@/api/organizations/index.js'

export default {
  /**
   * Get the business metrics for an organization.
   * @param {Number} organizationID The organization ID.
   * @returns {Promise<AxiosResponse>}
   */
  getBusinessMetrics(organizationID) {
    return instance.get(`${organizationsApiUrl}/${organizationID}/business_metrics`)
  },

  /**
   * Get a business metric for an organization.
   * @param {Number} organizationID The organization ID.
   * @param {Number} businessMetricID The business metric ID.
   * @returns {Promise<AxiosResponse>}
   */
  getBusinessMetric(organizationID, businessMetricID) {
    return instance.get(
      `${organizationsApiUrl}/${organizationID}/business_metrics/${businessMetricID}`
    )
  },

  /**
   * Create a business metric for an organization.
   * @param {Number} organizationID The organization ID.
   * @param {{ name: String, values: Array<String> }} payload
   * @returns {Promise<AxiosResponse>}
   */
  createBusinessMetric(organizationID, payload) {
    return instance.post(`${organizationsApiUrl}/${organizationID}/business_metrics`, payload)
  },

  /**
   * Edit a business metric for an organization.
   * @param {Number} organizationID The organization ID.
   * @param {Number} businessMetricID The business metric ID.
   * @param {{ name: String, values: Array<String> }} payload
   * @returns {Promise<AxiosResponse>}
   */
  editBusinessMetric(organizationID, businessMetricID, payload) {
    return instance.put(
      `${organizationsApiUrl}/${organizationID}/business_metrics/${businessMetricID}`,
      payload
    )
  },

  /**
   * Delete a business metric for an organization.
   * @param {Number} organizationID The organization ID.
   * @param {Number} businessMetricID The business metric ID.
   * @returns {Promise<AxiosResponse>}
   */
  deleteBusinessMetric(organizationID, businessMetricID) {
    return instance.delete(`${organizationsApiUrl}/${organizationID}/business_metrics/${businessMetricID}`)
  }
}
