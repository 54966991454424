import { computed } from 'vue'
import { useRecommendationsStore } from '@/store/recommendations.js'
import { ARN } from '@/common/link2aws'
import moment from 'moment'
import { providerColors } from '@/common/utils.js'
import { getFuckingAzureColumnsIndexes, getResourceName } from '@/common/cost-dashboard/utils.js'
import { storeToRefs } from 'pinia'
import { getServiceFromCommitment } from '@/common/cost-dashboard/recommendations-commitment.js'

export const RECOMMENDATIONS_CATEGORIES = [
  'cost',
  'security',
  'performance',
  'reliability',
  'manageability'
]
export const RECOMMENDATIONS_SUBCATEGORIES = ['unused', 'commitment', 'rightsizing']

export const PAYMENT_OPTIONS = {
  NO_UPFRONT: 'No upfront',
  PARTIAL_UPFRONT: 'Partial upfront',
  ALL_UPFRONT: 'All upfront'
}

export const TERMS = {
  TWELVE_MONTH: '1 year',
  ONE_YEAR: '1 year',

  THIRTY_SIX_MONTH: '3 years',
  THREE_YEARS: '3 years',

  P1Y: '1 year',
  P3Y: '3 years'
}

export const DIFFICULTY_MAPPING = {
  Low: 'Easy',
  VeryLow: 'Easy',
  High: 'Hard',
  VeryHigh: 'Hard'
}

export const RECOMMENDED_RESOURCE_TYPE = {
  ComputeSavingsPlans: 'COMPUTE_SP'
}

/**
 * Extract the necessary data from each provider's recommendation format.
 * @param {String} subcategory
 * @param {String} provider
 * @param {Object} account
 * @param {Object} obj
 * @returns {{service, savingsType, difficulty, summary, provider, resources, id, monthlySavingsPercentage: number, account: {provider, name, id}, monthlySavings: number}}
 */
function extractRecommendation(subcategory, provider, account, obj) {
  const savingsType = subcategory
  let id,
    resourceID,
    service,
    monthlySavings,
    monthlySavingsPercentage,
    resources,
    difficulty,
    summary,
    current,
    recommended,
    paymentOption,
    type,
    term,
    link,
    more = {}  // To store more info depending on the provider

  if (provider === 'aws') {
    id = obj.recommendationId
    resourceID = obj.resourceId
    if (obj.resourceArn) {
      const arnObj = new ARN(obj.resourceArn)
      service = arnObj.service
    } else {
      service = getServiceFromCommitment(obj)
    }
    monthlySavings = obj.estimatedMonthlySavings
    monthlySavingsPercentage = obj.estimatedSavingsPercentage
    resources = 1
    difficulty = DIFFICULTY_MAPPING[obj.implementationEffort] || obj.implementationEffort

    // Display a proper message for rightsizing.
    if (subcategory === 'rightsizing') {
      const {
        currentResourceSummary,
        recommendedResourceSummary,
        estimatedMonthlyCost: estimatedCostAfter,
        currencyCode: currency
      } = obj
      current = currentResourceSummary
      recommended = recommendedResourceSummary
      summary = `Switch from ${current} to ${recommended}.`
      const arn = new ARN(obj.resourceArn)
      link = `https://${arn.region}.console.aws.amazon.com/compute-optimizer/home?region=${arn.region}#/resource-details/${arn.service}/${arn.arn}`
      more = {
        estimatedCostBefore: estimatedCostAfter + monthlySavings,
        estimatedCostAfter,
        currency
      }
    } else if (subcategory === 'commitment') {
      const { recommendedResourceType } = obj
      type = getServiceFromCommitment(obj)
      more = {
        resourceType: RECOMMENDED_RESOURCE_TYPE[recommendedResourceType]
      }
    }
  } else if (provider === 'gcp') {
    id = obj.name
    const costObj = obj.primaryImpact?.costProjection?.cost
    if (costObj) {
      monthlySavings = Math.abs(parseFloat(`${costObj.units || 0}.${Math.abs(costObj.nanos)}`))
    } else {
      monthlySavings = 0
    }
    const foundService = obj.name.match(/google\.[a-zA-Z]+\.([a-zA-Z]+)/).pop()
    if (foundService) {
      service = foundService
    }
    if ('targetResources' in obj) {
      const [target] = obj.targetResources
      if (target) {
        resourceID = getResourceName(target, 'gcp')
      }
    }
    monthlySavingsPercentage = null
    resources = obj.targetResources?.length || 1
    summary = obj.description

    if (subcategory === 'commitment') {
      paymentOption = 'No upfront'
      service = type = obj.content.overview.type
      term = TERMS[obj.content.overview.plan]
      const { xorGroupId } = obj
      more = {
        xorGroupId
      }
    }

    link = 'https://console.cloud.google.com/active-assist/details/' + obj.name
  } else if (provider === 'azure') {
    id = obj.id
    monthlySavings = parseFloat(obj.extended_properties?.savingsAmount || '0')
    service = obj.impacted_field || 'Not found'
    resourceID = obj.resource_metadata?.resource_id
    resources = 1
    current = obj.short_description?.problem
    recommended = obj.short_description?.solution
    if (current === recommended) {
      current = resourceID
    }
    if (subcategory === 'rightsizing') {
      current = resourceID
    }
    if (subcategory === 'commitment') {
      paymentOption = 'No upfront'
      term = TERMS[obj.extended_properties?.term]
      more = {
        subscription: obj.extended_properties?.subId,
        savingsType: obj.extended_properties?.sku
      }
    }
  } else if (provider === 'oci') {
    id = obj.id
    monthlySavings = obj.estimated_cost_saving
    resourceID = obj.resource_id
    service = obj.resource_type
    resources = 1
  }

  return {
    provider,
    account: {
      id: account.id,
      name: account.name,
      provider
    },
    id,
    resourceID,
    service,
    savingsType,
    monthlySavings,
    monthlySavingsPercentage,
    resources,
    difficulty,
    current,
    recommended,
    summary,
    paymentOption,
    type,
    term,
    link,
    more
  }
}

const useRecommendations = (type, selectedAccounts, forceMonthly = false, filters = {}) => {
  const recommendationsStore = useRecommendationsStore()
  const { period } = storeToRefs(recommendationsStore)

  const recommendations = computed(() => {
    const subcategories = recommendationsStore.recommendations[type.value]
    if (!subcategories) {
      return []
    }
    let ret = []
    for (const subcategory in subcategories) {
      const recommendationsSubcategory = subcategories[subcategory]
      for (const providerData of recommendationsSubcategory) {
        for (const row of providerData.data) {
          ret.push(
            extractRecommendation(subcategory, providerData.provider, providerData.account, row)
          )
        }
      }
    }
    if (selectedAccounts && selectedAccounts.value.length) {
      return ret.filter(({ account: { id } }) => selectedAccounts.value.includes(id))
    }
    return ret.filter((recommendation) => {
      for (const key in filters) {
        if (recommendation[key] !== filters[key]) {
          return false
        }
      }
      return true
    }).sort((a, b) => a.monthlySavings > b.monthlySavings ? -1 : 1)
  })

  const savings = computed(() => {
    const types = {}

    const subcategories = recommendationsStore.recommendations[type.value]

    for (const subcategory in subcategories) {
      types[subcategory] = 0
    }

    for (const recommendation of recommendations.value) {
      if (
        selectedAccounts &&
        selectedAccounts.value.length &&
        !selectedAccounts.value.includes(recommendation.account.id)
      ) {
        continue
      }
      types[recommendation.savingsType] += recommendation.monthlySavings
    }

    types.total = Object.values(types).reduce((acc, curr) => acc + curr, 0)

    if (!forceMonthly && period.value === 'yearly') {
      for (const type in types) {
        types[type] = types[type] * 12
      }
    }

    return types
  })

  const accounts = computed(() => {
    const accs = recommendations.value.map((r) => r.account)
    const accountsPerProviders = {}
    for (const account of accs) {
      if (!(account.provider in accountsPerProviders)) {
        accountsPerProviders[account.provider] = new Set()
      }
      accountsPerProviders[account.provider].add(JSON.stringify(account))
    }
    for (const provider in accountsPerProviders) {
      accountsPerProviders[provider] = Array.from(accountsPerProviders[provider]).map((a) =>
        JSON.parse(a)
      )
    }
    return accountsPerProviders
  })

  const recommendationsPerType = computed(() => {
    const types = {}
    RECOMMENDATIONS_SUBCATEGORIES.forEach((subcategory) => {
      types[subcategory] = []
    })

    for (const recommendation of recommendations.value) {
      types[recommendation.savingsType].push(recommendation)
    }

    return Object.keys(types)
      .map((type) => ({
        type,
        nbRecommendations: types[type].length,
        totalSavings: types[type].reduce((acc, curr) => acc + curr.monthlySavings, 0)
      }))
      .sort((a, b) => (a.totalSavings > b.totalSavings ? -1 : 1))
  })

  return { recommendations, savings, accounts, recommendationsPerType }
}

const useCostsGraph = (type, selectedAccounts, showSavings) => {
  const recommendationsStore = useRecommendationsStore()
  const { savings } = useRecommendations(type, selectedAccounts, true)

  const graphData = computed(() => {
    const costs = {}
    const periodCosts = {}
    const addToCosts = (account, period, cost) => {
      if (!(account.id in costs)) {
        costs[account.id] = {
          account,
          periods: {}
        }
      }
      if (!(period in costs[account.id].periods)) {
        costs[account.id].periods[period] = 0
      }
      if (!(period in periodCosts)) {
        periodCosts[period] = 0
      }
      costs[account.id].periods[period] += cost
      periodCosts[period] += cost
    }
    for (const providerData of recommendationsStore.costs) {
      const {
        data,
        account: { id, name, provider_name }
      } = providerData
      // Check if the account is filtered.
      if (selectedAccounts.value.length && !selectedAccounts.value.includes(id)) {
        continue
      }
      const account = { id, name, provider: provider_name }
      if (provider_name === 'aws') {
        for (const row of data) {
          const period = row.TimePeriod.Start.substring(0, 7)
          const cost = row.Groups.reduce(
            (acc, curr) => acc + parseFloat(curr.Metrics.UnblendedCost.Amount),
            0
          )
          addToCosts(account, period, cost)
        }
      } else if (provider_name === 'gcp') {
        for (const row of data) {
          const period = row.entry_date.substring(0, 7)
          const cost = row.total_usd_cost
          addToCosts(account, period, cost)
        }
      } else if (provider_name === 'oci') {
        for (const row of data) {
          const period = row.time_usage_started.split('T')[0].substring(0, 7)
          const cost = parseFloat(row.attributed_cost)
          addToCosts(account, period, cost)
        }
      } else if (provider_name === 'azure') {
        for (const subscription of data) {
          const indexes = getFuckingAzureColumnsIndexes(subscription)
          for (const row of subscription.rows) {
            const strDate = row[indexes.date] + ''
            const period = strDate.substring(0, 4) + '-' + strDate.substring(4, 6)
            const cost = row[indexes.cost]
            addToCosts(account, period, cost)
          }
        }
      }
    }

    const periods = Array.from(
      new Set(
        Object.values(costs)
          .map((v) => Object.keys(v.periods))
          .flat()
      )
    ).sort()
    const labels = periods.map((period) => moment(period).format('MMM'))
    const datasets = Object.keys(costs).map((accountID) => {
      const accountRow = costs[accountID]
      return {
        id: Number(accountID),
        label: accountRow.account.name,
        data: periods.map((period) => accountRow.periods[period] || 0),
        backgroundColor: providerColors[accountRow.account.provider],
        order: 2
      }
    })

    if (showSavings.value) {
      const savingsValue = savings.value.total

      datasets.push({
        label: 'Savings',
        data: periods.map((period) => {
          const start = periodCosts[period] - savingsValue
          if (start > 0) {
            return [start, periodCosts[period]]
          }
          return [0, 0]
        }),
        backgroundColor: '#2ecc71',
        xAxisID: 'x2',
        order: 1
      })
    }

    return { labels, datasets }
  })

  return { graphData }
}

export { useRecommendations, useCostsGraph }
