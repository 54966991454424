import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import Oruga from '@oruga-ui/oruga-next'
import { bulmaConfig } from '@oruga-ui/theme-bulma'
import '@joint/plus/joint-plus.css'

import './holori.customize.scss'
import './css/main.css'
import './css/mdi.css'
import 'canvg'

import App from './App.vue'
import router from './router'

import { setupInterceptor, setupCurrentUser } from '@/api/interceptor'

import keyboardShortcutDirective from '@/common/keyboard-shortcut-directive'
import moment from 'moment'

import posthogPlugin from './plugins/posthog'
import { Crisp } from 'crisp-sdk-web'

const orugaConfig = Object.assign(bulmaConfig, {
  modal: {
    ...bulmaConfig.modal,
    canCancel: ['escape', 'outside', 'button']
  }
})

// TODO: Change this according to the user's locale.
// Reminder: `dow` is an Integer, representing the first day of the week.
// `1` means Monday, `0` means Sunday.
moment.updateLocale('en', {
  week: {
    dow: 1
  }
})

window.launchedRequests = []

const app = createApp(App)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)
setupInterceptor()
Crisp.configure(import.meta.env.VITE_CRISP_WEBSITE_ID)
await setupCurrentUser()
app.use(router)
app.use(VueReCaptcha, {
  siteKey: import.meta.env.VITE_CAPTCHA_SITE_KEY,
  loaderOptions: {
    autoHideBadge: true
  }
})
app.use(Oruga, orugaConfig)

if (import.meta.env.PROD) {
  app.use(posthogPlugin)
}

app.directive('keyboard-shortcut', keyboardShortcutDirective)

app.mount('#app')
