import instance from '@/api'
import priceServerUrl from '@/api/modules/price-server'

export const computeOffersApiUrl = priceServerUrl + 'offers'

const makeSort = ({ sortDir, sortBy }) => {
  if (!sortBy || !sortDir) {
    return undefined
  }
  if (sortBy === 'tenancy') {
    if (sortDir === 'asc') {
      return ['asc(dedicated)', 'asc(baremetal)']
    } else {
      return ['desc(baremetal)', 'desc(dedicated)']
    }
  }
  if (sortBy === 'price') {
    return null
  }
  return `${sortDir}(${sortBy})`
}

export default {
  getPage(type, offset, limit, filters, sort, signal) {
    const sortBy = sort ? makeSort(sort) : null

    return instance.get(computeOffersApiUrl + type, {
      params: {
        ...filters,
        offset,
        limit,
        sort_by: sortBy
      },
      signal
    })
  },
  getOffers(companyId, productName, type = 'compute', location = undefined, filters = {}) {
    return instance.get(computeOffersApiUrl + '/' + type, {
      params: {
        ...filters,
        company: companyId,
        name: productName,
        location
      }
    })
  },
  get(type, id) {
    return instance.get(`${computeOffersApiUrl}${type}${id}`)
  }
}
