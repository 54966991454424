import { defineStore } from 'pinia'
import moment from 'moment'
import { useProviderAccountsStore } from '@/store/provider-accounts.js'
import filtersApi from '@/api/organizations/filters.js'
import tagsApi from '@/api/organizations/tags.js'
import businessMetricsApi from '@/api/organizations/business-metrics.js'
import { extractFulfilledValues } from '@/common/utils.js'
import { periods } from '@/common/dashboard/periods.js'
import { getFuckingAzureColumnsIndexes } from '@/common/cost-dashboard/utils.js'
import mappingApi from '@/api/modules/public/mapping/mapping'
import { useAppStore } from '@/store/app.js'

const defaultDateRange = [moment().subtract(30, 'days').toDate(), moment().toDate()]

const sampleAccount = {
  id: 'demo',
  provider_name: 'aws',
  name: 'Holori Demo AWS'
}

export const defaultSelectedView = {
  name: '',
  rules: {
    filters: {},
    dates: defaultDateRange.map((d) => moment(d).format('YYYY-MM-DD')),
    period: ''
  }
}

export const supportedProviders = ['aws', 'azure', 'gcp', 'oci']

const useDashboardStore = defineStore('dashboard', {
  state: () => ({
    filters: {},
    datePeriod: '',
    dateRange: defaultDateRange,

    filteredProviderAccounts: [],

    /**
     * Views are stored in an array.
     *
     * {
     *   name: String,
     *   created_by: String,
     *   rules: Array<Array<Object>>
     * }
     */
    views: [],
    viewsFilter: '',
    selectedView: defaultSelectedView,

    tags: [],
    businessMetrics: [],
    virtualTags: [],

    costDashboardData: [],
    costBreakdownData: [],
    forecastedCostsData: [],
    averageDailyCostData: [],
    mostExpensiveResourcesData: [],
    costByResourceData: [],
    activeRegionsData: [],
    costForecastData: [],
    allResourcesData: {},
    resourcesData: {},
    servicesData: {},
    costForResourceData: [],

    loading: {
      costDashboardData: false,
      costBreakdownData: false,
      forecastedCostsData: false,
      averageDailyCostData: false,
      mostExpensiveResourcesData: false,
      costByResourceData: false,
      activeRegionsData: false,
      costForecastData: false,
      allResourcesData: false,
      costForResourceData: false
    }
  }),

  persist: {
    storage: localStorage,
    paths: ['datePeriod', 'dateRange'],
    serializer: {
      deserialize: (v) => {
        const parsed = JSON.parse(v)
        parsed.dateRange = parsed.dateRange.map((d) => moment(d).toDate())
        if (parsed.datePeriod) {
          const period = periods.find((p) => p.label === parsed.datePeriod)
          const [start, end] = period.range.map((m) => m.toDate())
          parsed.dateRange = [start, end]
        }
        return parsed
      },
      serialize: JSON.stringify
    }
  },

  getters: {
    getFilters: ({ filters }) => filters,

    getAPIFilters: ({ filters }) => {
      const excluded = ['cost', 'cloud_provider']
      const ret = {}
      for (const key in filters) {
        if (!excluded.includes(key)) {
          ret[key] = filters[key]
        }
      }
      return ret
    },

    getAllResourcesFromID: ({ costDashboardData }) => {
      const azureAccount = costDashboardData.find((row) => row.provider === 'azure')
      if (azureAccount) {
        const resourceID =
          '/subscriptions/95675234-124a-4a5d-8774-18745a969c54/resourceGroups/rg-upp-devops/providers/Microsoft.Storage/storageAccounts/steueuropauppalzshell'
        return azureAccount.data.filter((row) => row.resource_id === resourceID)
      }
      return []
    },

    /**
     * Get all the currently used fields.
     * @returns {Array<String>}
     */
    getUsedFields: ({ filters }) => Object.keys(filters),

    getStringifiedFilters: ({ filters }) => JSON.stringify(filters),

    /**
     * Get the available views.
     * @returns {Array<Object>}
     */
    getViews: ({ views }) => views,

    /**
     * Get the views filter, to lower case.
     * @returns {String}
     */
    getViewsFilter: ({ viewsFilter }) => viewsFilter.toLowerCase(),

    /**
     * Get the views, filtered by the user's query.
     * @returns {Array<Object>}
     */
    getFilteredViews({ views }) {
      return views.filter(
        (view) =>
          view.name.toLowerCase().includes(this.getViewsFilter) ||
          view.created_by?.toLowerCase().includes(this.getViewsFilter)
      )
    },

    /**
     * Get the selected view object.
     * @param {Object} selectedView
     * @returns {Object}
     */
    getSelectedView: ({ selectedView }) => selectedView,

    /**
     * Get the selected view name, if it exists.
     * @param {Object} selectedView
     * @returns {String}
     */
    getSelectedViewName: ({ selectedView }) => selectedView?.name,

    /**
     * Indicate if the current view's filters have changed.
     * @returns {boolean}
     */
    viewHasChanged: ({ selectedView, getFilters, dateRange, datePeriod }) => {
      const { filters, dates, period } = selectedView.rules
      const filtersChanged = JSON.stringify(filters) !== JSON.stringify(getFilters)
      const datesChanged =
        dates.length > 0 &&
        (dates[0] !== moment(dateRange[0]).format('YYYY-MM-DD') ||
          dates[1] !== moment(dateRange[1]).format('YYYY-MM-DD'))
      const periodChanged = period !== '' && period !== datePeriod
      return filtersChanged || (selectedView?.id && (datesChanged || periodChanged))
    },

    getFilteredData: (state) => (key) => {
      return state[key + 'Data'].map((row) => {
        return row
      })
    },

    getNumberOfAssets:
      ({ mostExpensiveResourcesData }) =>
      (accountID) => {
        const found = mostExpensiveResourcesData.find((row) => row.account.id === accountID)
        if (!found) {
          return null
        }

        switch (found.provider) {
          case 'aws':
            return found.data[0].Groups.length
          case 'azure':
            return Array.from(new Set(found.data.map((row) => row.instance_name))).length
          case 'gcp':
            return Array.from(new Set(found.data.map((row) => row.resource_global_name || 'Other')))
              .length
          case 'oci':
            return Array.from(new Set(found.data.map((row) => row.resource_id))).length
        }

        return 0
      },

    /**
     * Get total number of assets.
     * @param {Array<Object>} mostExpensiveResourcesData
     * @returns {Number}
     */
    getTotalNumberOfAssets: ({ mostExpensiveResourcesData }) => {
      return mostExpensiveResourcesData.reduce((total, curr) => {
        switch (curr.provider) {
          case 'aws':
            return total + curr.data[0].Groups.length
          case 'azure':
            return total + Array.from(new Set(curr.data.map((row) => row.resource_id))).length
          case 'gcp':
            return (
              total +
              Array.from(new Set(curr.data.map((row) => row.resource_global_name || 'Other')))
                .length
            )
          case 'oci':
            return total + Array.from(new Set(curr.data.map((row) => row.resource_id))).length
          default:
            return total
        }
      }, 0)
    },

    supportedProviderAccounts: ({ filteredProviderAccounts }) =>
      filteredProviderAccounts.filter((account) =>
        supportedProviders.includes(account.provider_name)
      ),

    /**
     * Returns the available virtual tags to add on individual resources.
     * @returns {Object}
     */
    virtualTagsKeyValues: ({ virtualTags }) => {
      const tags = {}

      virtualTags
        .filter(({ values }) => !!values[0]?.resources)
        .forEach((tag) => {
          tags[tag.id] = {
            key: tag.name,
            values: tag.values.map(({ value }) => value)
          }
        })

      return tags
    }
  },

  actions: {
    /**
     * Save the filters in the store.
     * @param {Object} filters
     */
    setFilters(filters) {
      this.filters = JSON.parse(JSON.stringify(filters || {}))
    },

    /**
     * Save the views filter.
     * @param {String} viewsFilter
     */
    setViewsFilter(viewsFilter) {
      this.viewsFilter = viewsFilter
    },

    /**
     * Set the selected view.
     * @param {Object} selectedView
     */
    setSelectedView(selectedView) {
      this.selectedView = selectedView
      this.filters = selectedView.rules.filters || {}
    },

    /**
     * Restore the filters to a new clean view.
     */
    newView() {
      this.setSelectedView(defaultSelectedView)
    },

    setDatePeriod(period) {
      this.datePeriod = period.label
      const [start, end] = period.range.map((m) => m.toDate())
      this.dateRange = [start, end]
    },

    setDateRange(dateRange) {
      this.datePeriod = ''
      this.dateRange = dateRange
    },

    /**
     * Filter the accounts on which to get the data.
     * @param {Number} organizationID The organization ID.
     * @param {String} provider Filter only accounts with this provider.
     * @returns {Promise<Array<Object>>}
     */
    filterAllProviderAccounts(organizationID, provider = null) {
      const providerAccountsStore = useProviderAccountsStore()
      return new Promise((bigResolve) => {
        providerAccountsStore
          .getProviderAccounts(organizationID, { enabled: true, provider_name: provider })
          .then((accounts) => {
            this.filteredProviderAccounts = accounts
            bigResolve(accounts)
          })
      })
    },

    setFilteredProviderAccounts(filteredProviderAccounts) {
      this.filteredProviderAccounts = filteredProviderAccounts
    },

    filterProviderAccounts() {
      const providerAccountsStore = useProviderAccountsStore()
      let accounts = providerAccountsStore.providerAccounts
      if ('cloud_provider' in this.filters) {
        const { operator, value } = this.filters.cloud_provider
        accounts = accounts.filter(({ provider_name }) =>
          operator === 'is' ? value.includes(provider_name) : !value.includes(provider_name)
        )
      } else if ('cloud_account' in this.filters) {
        const { operator, value } = this.filters.cloud_account
        accounts = accounts.filter(({ id }) =>
          operator === 'is' ? value.includes(id) : !value.includes(id)
        )
      }
      this.setFilteredProviderAccounts(accounts)
    },

    _fetchAWSTags(account, data, groupBy) {
      if (account.provider_name === 'aws' && (!groupBy || groupBy === 'RESOURCE_ID')) {
        const providerAccountsStore = useProviderAccountsStore()
        const organizationID = account.organization_id
        /** @var {Set<String>} */
        const uniqueResourcesIDs = new Set()
        data[0].Groups.forEach((group) => {
          const [resourceID] = group.Keys
          uniqueResourcesIDs.add(resourceID.toLowerCase())
        })

        const resourcesIDs = Array.from(uniqueResourcesIDs)
        resourcesIDs.forEach((resourceID) => {
          this.resourcesData[resourceID] = {
            region: 'loading',
            service: 'loading',
            tags: 'loading'
          }
        })

        const chunkSize = 10

        for (let i = 0; i < resourcesIDs.length; i += chunkSize) {
          const chunk = resourcesIDs.slice(i, i + chunkSize)
          const query = chunk.map((resourceID) => `"${resourceID}"`).join(' or ')
          providerAccountsStore.search(organizationID, account.id, query).then(({ data }) => {
            chunk.forEach((resourceID) => {
              const found = data.find(({ Arn }) => Arn.endsWith(resourceID))
              let region, service, tags

              // If there's a result, take the data from it.
              if (found) {
                region = found.Region
                service = found.Service
                tags = found.Properties.find((prop) => prop.Name === 'tags')?.Data || []
              } else {
                const split = resourceID.split(':')
                split.shift()
                split.shift()
                service = split.shift()
                region = split.shift()
                tags = []
              }

              // Save the newly gotten data.
              this.resourcesData[resourceID] = {
                ...this.resourcesData[resourceID],
                region: region ? region : 'Not found',
                service: service ? service : 'Not found',
                tags: tags.map((tag) => ({
                  key: tag.key || tag.Key,
                  value: tag.value || tag.Value
                }))
              }
            })
          })
        }
      }
    },

    _fetchNumberOfResources(account, data, groupBy, startDate, endDate) {
      const { id, organization_id, provider_name } = account

      if ((provider_name === 'aws' || provider_name === 'gcp') && groupBy === 'SERVICE') {
        const services = new Set()

        if (provider_name === 'aws') {
          for (const row of data) {
            for (const group of row.Groups) {
              services.add(group.Keys[0])
            }
          }
        } else if (provider_name === 'gcp') {
          for (const row of data) {
            services.add(row.service_description)
          }
        }
        const servicesArr = Array.from(services)

        const providerAccountsStore = useProviderAccountsStore()

        for (const service of servicesArr) {
          providerAccountsStore
            .getResources(organization_id, id, startDate, endDate, {
              cloud_service: {
                operator: 'is',
                value: service
              }
            })
            .then((res) => {
              if (res.data) {
                let count
                if (provider_name === 'aws') {
                  count = res.data.map(({ Value }) => Value).filter((v) => v !== '').length
                } else if (provider_name === 'gcp') {
                  count = res.data
                    .map(({ resource_global_name }) => resource_global_name)
                    .filter((v) => v !== null).length
                }
                this.servicesData[service] = { count }
              }
            })
        }
      }
    },

    _fetchAzureTags(account, data) {
      if (account.provider_name === 'azure') {
        const providerAccountsStore = useProviderAccountsStore()

        const subscriptions = {}

        data.forEach((subscription) => {
          const indexes = getFuckingAzureColumnsIndexes(subscription)
          subscription.rows.forEach((row) => {
            const subscriptionID = row[indexes.subscriptionID]
            const resourceID = row[indexes.resourceID]

            if (!(subscriptionID in subscriptions)) {
              subscriptions[subscriptionID] = new Set()
            }

            subscriptions[subscriptionID].add(resourceID)
          })
        })

        for (const subscriptionID in subscriptions) {
          Array.from(subscriptions[subscriptionID]).forEach((resourceID) => {
            providerAccountsStore.getAzureDetails(account.organization_id, account.id, resourceID)
          })
        }
      }
    },

    _saveCostDashboardData(bigResolve) {
      return (results) => {
        const { allData, allErrors } = extractFulfilledValues(results)

        this.costDashboardData = allData
        this.costBreakdownData = allData.map((line) => {
          return {
            ...line,
            data: line.data
          }
        })
        this.loading.costDashboardData = false
        this.loading.costBreakdownData = false
        bigResolve({ allData, allErrors })
      }
    },

    /**
     * Get cost and usage.
     * @param {Number} organizationID The organization ID.
     * @param {String} startDate `YYYY-MM-DD`.
     * @param {String} endDate `YYYY-MM-DD`.
     * @param {String} startDateAWS `YYYY-MM-DD`.
     * @param {String} endDateAWS `YYYY-MM-DD`.
     * @param {Null|String} groupBy Field to group by.
     * @param {null|Object} rules Rules to filter.
     * @returns {Promise<Array<Object>>}
     */
    getCostDashboardData(
      organizationID,
      startDate,
      endDate,
      startDateAWS,
      endDateAWS,
      groupBy = null,
      rules = null
    ) {
      this.loading.costDashboardData = true
      this.loading.costBreakdownData = true
      this.costDashboardData = []
      this.costBreakdownData = []

      const providerAccountsStore = useProviderAccountsStore()
      const appStore = useAppStore()

      return new Promise((bigResolve) => {
        const promises = this.supportedProviderAccounts
          .filter((account) => {
            if (account.provider_name !== 'aws' || groupBy !== 'RESOURCE_ID') {
              return true
            }
            return startDateAWS !== null && endDateAWS !== null
          })
          .map((account) => {
            return new Promise((resolve, reject) => {
              // Force Azure to use always RESOURCE_ID
              let usedGroupBy = !groupBy ? 'SERVICE' : groupBy
              if (account.provider_name === 'azure' && groupBy !== 'TAGS') {
                usedGroupBy = 'RESOURCE_ID'
              }
              providerAccountsStore
                .getCost(
                  organizationID,
                  account.id,
                  {
                    group_by: usedGroupBy,
                    start_date: startDate,
                    end_date: endDate
                  },
                  rules || this.getAPIFilters
                )
                .then(
                  ({ data }) => {
                    if (account.provider_name === 'aws' && groupBy === 'RESOURCE_ID') {
                      this._fetchAWSTags(account, data)
                    }
                    resolve({ provider: account.provider_name, account, data })
                  },
                  (e) => {
                    reject(
                      e.message === 'canceled'
                        ? 'canceled'
                        : `Could not get costs data for account ${account.name}`
                    )
                  }
                )
                .finally(() => appStore.increaseValLoading())
            })
          })

        appStore.increaseMaxLoading(promises.length)

        Promise.allSettled(promises).then(this._saveCostDashboardData(bigResolve))
      })
    },

    getCostDashboardSampleData() {
      this.loading.costDashboardData = true
      this.loading.costBreakdownData = true
      this.costDashboardData = []
      this.costBreakdownData = []

      return new Promise((bigResolve) => {
        const promise = new Promise((resolve) => {
          mappingApi.getPublic('product-tour-data/cost-service.json').then(({ data }) => {
            resolve({ provider: sampleAccount.provider_name, account: sampleAccount, data })
          })
        })

        Promise.allSettled([promise]).then(this._saveCostDashboardData(bigResolve))
      })
    },

    /**
     * Get previous period costs.
     *
     * This does not save anything into the store, but rather resolves the data.
     * @param {Number} organizationID The organization ID.
     * @param {String} startDate `YYYY-MM-DD`.
     * @param {String} endDate `YYYY-MM-DD`.
     * @returns {Promise<Array<Object>>}
     */
    getPreviousPeriodCosts(organizationID, startDate, endDate) {
      const providerAccountsStore = useProviderAccountsStore()
      return new Promise((bigResolve) => {
        const promises = this.supportedProviderAccounts.map((account) => {
          return new Promise((resolve, reject) => {
            providerAccountsStore
              .getCost(
                organizationID,
                account.id,
                {
                  group_by: 'SERVICE',
                  start_date: startDate,
                  end_date: endDate
                },
                {}
              )
              .then(
                ({ data }) => {
                  resolve({ provider: account.provider_name, account, data })
                },
                (e) => {
                  reject(
                    e.message === 'canceled'
                      ? 'canceled'
                      : `Could not get costs data for account ${account.name}`
                  )
                }
              )
          })
        })

        Promise.allSettled(promises).then((results) => {
          const { allData, allErrors } = extractFulfilledValues(results)
          bigResolve({ allData, allErrors })
        })
      })
    },

    /**
     * Get most expensive resources.
     * @param {Number} organizationID The organization ID.
     * @param {String} startDate Start date. Format `YYYY-MM-DD`.
     * @param {String} startDateAWS Start date to use for AWS. Format `YYYY-MM-DD`.
     * @param {String} endDate End date. Format `YYYY-MM-DD`.
     * @param {String} endDateAWS End date to use for AWS. Format `YYYY-MM-DD`.
     * @returns {Promise<Array<{ provider: String, data: Array<Object>}>>}
     */
    getMostExpensiveResources(organizationID, startDate, startDateAWS, endDate, endDateAWS) {
      this.loading.mostExpensiveResourcesData = true
      this.mostExpensiveResourcesData = []

      const providerAccountsStore = useProviderAccountsStore()
      const appStore = useAppStore()

      return new Promise((bigResolve) => {
        const promises = this.supportedProviderAccounts
          .filter((account) => {
            if (account.provider_name !== 'aws') {
              return true
            }
            return startDateAWS !== null && endDateAWS !== null
          })
          .map((account) => {
            return new Promise((resolve, reject) => {
              providerAccountsStore
                .getCost(
                  organizationID,
                  account.id,
                  {
                    group_by: 'RESOURCE_ID',
                    // Because of AWS limitation of 14 days, we need to have specific date for them.
                    start_date: account.provider_name === 'aws' ? startDateAWS : startDate,
                    end_date: account.provider_name === 'aws' ? endDateAWS : endDate
                  },
                  this.getAPIFilters
                )
                .then(
                  ({ data }) => {
                    this._fetchAWSTags(account, data)
                    resolve({ provider: account.provider_name, account, data })
                  },
                  (e) => {
                    reject(
                      e.message === 'canceled'
                        ? 'canceled'
                        : `Could not get most expensive resources for account ${account.name}.`
                    )
                  }
                )
                .finally(() => appStore.increaseValLoading())
            })
          })

        appStore.increaseMaxLoading(promises.length)

        Promise.allSettled(promises).then((results) => {
          const { allData, allErrors } = extractFulfilledValues(results)

          this.mostExpensiveResourcesData = allData
          this.loading.mostExpensiveResourcesData = false

          bigResolve({ allData, allErrors })
        })
      })
    },

    _saveInventoryData(bigResolve, groupBy) {
      return (results) => {
        const { allData, allErrors } = extractFulfilledValues(results)

        this.allResourcesData[groupBy] = allData.filter((row) => row !== undefined)
        this.loading.allResourcesData = false

        bigResolve({ allData, allErrors })
      }
    },

    /**
     * Get all resources.
     * @param {Number} organizationID The organization ID.
     * @param {String} startDate Start date. Format `YYYY-MM-DD`.
     * @param {String} endDate End date. Format `YYYY-MM-DD`.
     * @param {String} startDateAWS Start date for AWS. Format `YYYY-MM-DD`.
     * @param {String} endDateAWS End date for AWS. Format `YYYY-MM-DD`.
     * @param {null|String} groupBy Group by.
     * @returns {Promise<Array<{ provider: String, data: Array<Object>}>>}
     */
    getAllResources(organizationID, startDate, endDate, startDateAWS, endDateAWS, groupBy = null) {
      if (groupBy === 'TAGS' || groupBy === 'TAGS_VALUES') {
        return new Promise((resolve) => {
          resolve({ allErrors: [] })
        })
      }

      this.allResourcesData[groupBy] = []
      this.loading.allResourcesData = true

      const providerAccountsStore = useProviderAccountsStore()
      const appStore = useAppStore()

      return new Promise((bigResolve) => {
        const promises = this.supportedProviderAccounts
          .filter((account) => {
            if (account.provider_name !== 'aws' || groupBy !== 'RESOURCE_ID') {
              return true
            }
            return startDateAWS !== null || endDateAWS !== null
          })
          .map(
            (account) =>
              new Promise((resolve, reject) => {
                providerAccountsStore
                  .getCost(
                    organizationID,
                    account.id,
                    {
                      start_date:
                        account.provider_name === 'aws' && groupBy === 'RESOURCE_ID'
                          ? startDateAWS
                          : startDate,
                      end_date:
                        account.provider_name === 'aws' && groupBy === 'RESOURCE_ID'
                          ? endDateAWS
                          : endDate,
                      group_by: !groupBy ? 'RESOURCE_ID' : groupBy
                    },
                    this.getAPIFilters
                  )
                  .then(
                    ({ data }) => {
                      // Now fetch the tags...
                      this._fetchAWSTags(account, data, groupBy)
                      this._fetchAzureTags(account, data, groupBy)
                      this._fetchNumberOfResources(account, data, groupBy, startDateAWS, endDateAWS)
                      // this._fetchAzureTags(account, data)
                      resolve({ provider: account.provider_name, account, data })
                    },
                    (e) => {
                      reject(
                        e.message === 'canceled'
                          ? 'canceled'
                          : `Could not get all resources for account ${account.name}.`
                      )
                    }
                  )
                  .finally(() => appStore.increaseValLoading())
              })
          )

        appStore.increaseMaxLoading(promises.length)

        Promise.allSettled(promises).then(this._saveInventoryData(bigResolve, groupBy))
      })
    },

    getAllResourcesSampleData() {
      this.allResourcesData.RESOURCE_ID = []
      this.loading.allResourcesData = true

      return new Promise((bigResolve) => {
        const promise = new Promise((resolve) => {
          mappingApi.getPublic('product-tour-data/cost-resource.json').then(({ data }) => {
            mappingApi
              .getPublic('product-tour-data/resources-data.json')
              .then(({ data: resourcesData }) => {
                this.resourcesData = resourcesData
                resolve({ provider: sampleAccount.provider_name, account: sampleAccount, data })
              })
          })
        })

        const promises = [promise]

        Promise.allSettled(promises).then(this._saveInventoryData(bigResolve, 'RESOURCE_ID'))
      })
    },

    getCostByResource(
      organizationID,
      accounts,
      service,
      startDate,
      endDate,
      startDateAWS,
      endDateAWS,
      groupBy
    ) {
      this.costByResourceData = []
      this.loading.costByResourceData = true

      const providerAccountsStore = useProviderAccountsStore()

      const rules = {
        ...this.getAPIFilters,
        cloud_service: {
          operator: 'is',
          value: service
        }
      }

      return new Promise((bigResolve) => {
        const promises = accounts.map(
          (account) =>
            new Promise((resolve, reject) => {
              const promiseGlobal = providerAccountsStore
                .getCost(
                  organizationID,
                  account.id,
                  {
                    group_by: 'SERVICE',
                    start_date: account.provider_name === 'aws' ? startDateAWS : startDate,
                    end_date: account.provider_name === 'aws' ? endDateAWS : endDate
                  },
                  rules
                )
                .catch((e) =>
                  reject(
                    e.message === 'canceled'
                      ? 'canceled'
                      : `Could not get cost by resource for account ${account.name}.`
                  )
                )
              const promiseDetail = providerAccountsStore
                .getCost(
                  organizationID,
                  account.id,
                  {
                    group_by: groupBy,
                    start_date: account.provider_name === 'aws' ? startDateAWS : startDate,
                    end_date: account.provider_name === 'aws' ? endDateAWS : endDate
                  },
                  rules
                )
                .catch((e) =>
                  reject(
                    e.message === 'canceled'
                      ? 'canceled'
                      : `Could not get cost by resource for account ${account.name}.`
                  )
                )

              Promise.allSettled([promiseGlobal, promiseDetail]).then((values) => {
                const detailData = values.find((r) => r.value.config.params.group_by !== 'SERVICE')
                  .value?.data || []
                const globalData = values.find((r) => r.value.config.params.group_by === 'SERVICE')
                  .value?.data || []

                if (account.provider_name === 'aws' && groupBy === 'RESOURCE_ID') {
                  this._fetchAWSTags(account, detailData)
                }

                resolve({
                  provider: account.provider_name,
                  account,
                  data: detailData,
                  global: globalData
                })
              })
            })
        )

        Promise.allSettled(promises).then((results) => {
          const { allData, allErrors } = extractFulfilledValues(results)

          this.costByResourceData = allData
          this.loading.costByResourceData = false

          bigResolve({ allData, allErrors })
        })
      })
    },

    /**
     * Get cost for resource.
     * @param {Number} organizationID
     * @param {Object} account
     * @param {String} resourceID
     * @param {String} service
     * @param {String} groupBy
     * @param {String} startDate
     * @param {String} endDate
     * @param {String} startDateAWS
     * @param {String} endDateAWS
     * @returns {Promise}
     */
    getCostForResource(
      organizationID,
      account,
      resourceID,
      service,
      groupBy,
      startDate,
      endDate,
      startDateAWS,
      endDateAWS
    ) {
      const providerAccountsStore = useProviderAccountsStore()
      this.costForResourceData = {}
      this.loading.costForResourceData = true

      const rules = {
        ...this.getAPIFilters,
        resource_name: {
          operator: 'is',
          value: resourceID
        }
      }

      if (service) {
        rules.cloud_service = {
          operator: 'is',
          value: service
        }
      }

      return new Promise((resolve, reject) => {
        providerAccountsStore
          .getCost(
            organizationID,
            account.id,
            {
              group_by: groupBy,
              start_date: account.provider_name === 'aws' ? startDateAWS : startDate,
              end_date: account.provider_name === 'aws' ? endDateAWS : endDate
            },
            rules
          )
          .then(
            ({ data }) => {
              this.costForResourceData = { provider: account.provider_name, data }
              this.loading.costForResourceData = false
              resolve(data)
            },
            (e) => {
              reject(
                e.message === 'canceled'
                  ? 'canceled'
                  : `Could not get cost for resource for account ${account.name}.`
              )
            }
          )
      })
    },

    getActiveRegions(organizationID, startDate, endDate) {
      this.loading.activeRegionsData = true
      this.activeRegionsData = []

      const providerAccountsStore = useProviderAccountsStore()
      const appStore = useAppStore()

      return new Promise((bigResolve) => {
        const promises = this.supportedProviderAccounts.map((account) => {
          return new Promise((resolve, reject) => {
            providerAccountsStore
              .getRegions(organizationID, account.id, startDate, endDate)
              .then(
                ({ data }) => {
                  resolve({ provider: account.provider_name, account, data })
                },
                (e) => {
                  reject(
                    e.message === 'canceled'
                      ? 'canceled'
                      : `Could not get active regions for account ${account.name}.`
                  )
                }
              )
              .finally(() => appStore.increaseValLoading())
          })
        })

        appStore.increaseMaxLoading(promises.length)

        Promise.allSettled(promises).then((results) => {
          const { allData, allErrors } = extractFulfilledValues(results)

          this.activeRegionsData = allData
          this.loading.activeRegionsData = false

          bigResolve({ allData, allErrors })
        })
      })
    },

    /**
     * Get cost forecast.
     * @param {Number} organizationID The organization ID.
     * @param {String} startDateCurrent Start date. Format `YYYY-MM-DD`.
     * @param {String} endDateCurrent End date. Format `YYYY-MM-DD`.
     * @param {String} startDateForecast Start date. Format `YYYY-MM-DD`.
     * @param {String} endDateForecast End date. Format `YYYY-MM-DD`.
     * @returns {Promise<Array<{ provider: String, data: Array<Object>}>>}
     */
    getCostForecast(
      organizationID,
      startDateCurrent,
      endDateCurrent,
      startDateForecast,
      endDateForecast
    ) {
      this.loading.costForecastData = true
      this.costForecastData = []

      const providerAccountsStore = useProviderAccountsStore()
      const appStore = useAppStore()

      return new Promise((bigResolve) => {
        const actualCostsPromises = this.supportedProviderAccounts.map(
          (account) =>
            new Promise((resolve, reject) => {
              providerAccountsStore
                .getCost(organizationID, account.id, {
                  group_by: 'RESOURCE_ID',
                  start_date: startDateCurrent,
                  end_date: endDateCurrent
                })
                .then(
                  ({ data }) => {
                    resolve({ provider: account.provider_name, account, current: true, data })
                  },
                  (e) => {
                    reject(
                      e.message === 'canceled'
                        ? 'canceled'
                        : `Could not get current cost for forecast for account ${account.name}.`
                    )
                  }
                )
                .finally(() => appStore.increaseValLoading())
            })
        )
        const forecastPromises = this.supportedProviderAccounts.map(
          (account) =>
            new Promise((resolve, reject) => {
              providerAccountsStore
                .getCostForecast(
                  organizationID,
                  account.id,
                  {
                    start_date: startDateForecast,
                    end_date: endDateForecast
                  },
                  {}
                )
                .then(
                  ({ data }) => {
                    resolve({ provider: account.provider_name, account, data })
                  },
                  (e) => {
                    reject(
                      e.message === 'canceled'
                        ? 'canceled'
                        : `Could not get cost forecast for account ${account.name}.`
                    )
                  }
                )
                .finally(() => appStore.increaseValLoading())
            })
        )
        const promises = [...actualCostsPromises, ...forecastPromises]

        appStore.increaseMaxLoading(promises.length)

        Promise.allSettled(promises).then((results) => {
          const { allData, allErrors } = extractFulfilledValues(results)

          this.costForecastData = allData.filter((row) => row !== undefined)
          this.loading.costForecastData = false

          bigResolve({ allData, allErrors })
        })
      })
    },

    getCostForecastSampleData() {
      this.loading.costForecastData = true
      this.costForecastData = []

      const account = {
        id: 'sample',
        provider_name: 'aws',
        name: 'Holori Sample AWS'
      }

      return new Promise((bigResolve) => {
        const actualCostsPromise = new Promise((resolve) => {
          mappingApi.getPublic('product-tour-data/cost.json').then(({ data }) => {
            resolve({ provider: 'aws', account, current: true, data })
          })
        })

        const forecastPromise = new Promise((resolve) => {
          mappingApi.getPublic('product-tour-data/forecast.json').then(({ data }) => {
            resolve({ provider: 'aws', account, data })
          })
        })

        const promises = [actualCostsPromise, forecastPromise]

        Promise.allSettled(promises).then((results) => {
          const { allData, allErrors } = extractFulfilledValues(results)

          this.costForecastData = allData.filter((row) => row !== undefined)
          this.loading.costForecastData = false

          bigResolve({ allData, allErrors })
        })
      })
    },

    /**
     * Get available services..
     * @param {Number} organizationID The organization ID.
     * @param {String} startDate The start date.
     * @param {String} endDate The end date.
     * @returns {Promise<AxiosResponse>}
     */
    getAvailableServices(organizationID, startDate, endDate) {
      const providerAccountsStore = useProviderAccountsStore()

      return new Promise((bigResolve) => {
        const promises = this.supportedProviderAccounts.map(
          ({ id: accountID, provider_name, name }) =>
            new Promise((resolve, reject) =>
              providerAccountsStore
                .getServices(organizationID, accountID, startDate, endDate, this.getAPIFilters)
                .then(
                  ({ data }) => {
                    resolve({ provider_name, data })
                  },
                  (e) => {
                    reject(
                      e.message === 'canceled'
                        ? 'canceled'
                        : `Could not available services for account ${name}.`
                    )
                  }
                )
            )
        )

        Promise.allSettled(promises).then((results) => {
          const { allData, allErrors } = extractFulfilledValues(results)

          const services = []

          for (const curr of allData) {
            const { provider_name, data } = curr
            if (provider_name === 'aws') {
              data.forEach((row) => {
                if (row.Value) {
                  services.push('aws/' + row.Value)
                }
              })
            } else if (provider_name === 'gcp') {
              data.forEach((row) => {
                if (row.service_description !== null) {
                  services.push('gcp/' + row.service_description)
                }
              })
            } else if (provider_name === 'azure') {
              data.forEach((subscription) => {
                subscription.rows.forEach((row) => {
                  services.push('azure/' + row[0])
                })
              })
            } else if (provider_name === 'oci') {
              data.forEach((row) => {
                services.push('oci/' + row.name)
              })
            }
          }

          bigResolve({ allData: services, allErrors })
        })
      })
    },

    /**
     * Get available regions.
     * @param {Number} organizationID The organization ID.
     * @param {String} startDate The start date.
     * @param {String} endDate The end date.
     * @returns {Promise<AxiosResponse>}
     */
    getAvailableRegions(organizationID, startDate, endDate) {
      const providerAccountsStore = useProviderAccountsStore()

      return new Promise((bigResolve) => {
        const promises = this.supportedProviderAccounts.map(
          ({ id: accountID, provider_name, name }) =>
            new Promise((resolve, reject) =>
              providerAccountsStore
                .getRegions(organizationID, accountID, startDate, endDate, this.getAPIFilters)
                .then(
                  ({ data }) => {
                    resolve({ provider_name, data })
                  },
                  (e) => {
                    reject(
                      e.message === 'canceled'
                        ? 'canceled'
                        : `Could not available services for account ${name}.`
                    )
                  }
                )
            )
        )

        Promise.allSettled(promises).then((results) => {
          const { allData, allErrors } = extractFulfilledValues(results)

          const regions = []

          for (const curr of allData) {
            const { provider_name, data } = curr
            if (provider_name === 'aws') {
              data.forEach((row) => {
                if (row.Value) {
                  regions.push('aws/' + row.Value)
                }
              })
            } else if (provider_name === 'gcp') {
              data.forEach((row) => {
                if (row.resource_global_name !== null) {
                  regions.push('gcp/' + JSON.parse(row.location).location)
                }
              })
            } else if (provider_name === 'azure') {
              data.forEach((subscription) => {
                subscription.rows.forEach((row) => {
                  regions.push('azure/' + row[0])
                })
              })
            } else if (provider_name === 'oci') {
              regions.push(...data.map((region) => `oci/${region}`))
            }
          }

          bigResolve({ allData: regions, allErrors })
        })
      })
    },

    /**
     * Get available resources IDs.
     * @param {Number} organizationID The organization ID.
     * @param {String} startDate The start date.
     * @param {String} endDate The end date.
     * @param {String} startDateAWS The start date for AWS.
     * @param {String} endDateAWS The end date for AWS.
     * @returns {Promise<AxiosResponse>}
     */
    getAvailableResourcesIDs(organizationID, startDate, endDate, startDateAWS, endDateAWS) {
      const providerAccountsStore = useProviderAccountsStore()

      return new Promise((bigResolve) => {
        const promises = this.supportedProviderAccounts.map(
          ({ id: accountID, provider_name, name }) =>
            new Promise((resolve, reject) =>
              providerAccountsStore
                .getResources(
                  organizationID,
                  accountID,
                  provider_name === 'aws' ? startDateAWS : startDate,
                  provider_name === 'aws' ? endDateAWS : endDate,
                  this.getAPIFilters
                )
                .then(
                  ({ data }) => {
                    resolve({ provider_name, data })
                  },
                  (e) => {
                    reject(
                      e.message === 'canceled'
                        ? 'canceled'
                        : `Could not available resources IDs for account ${name}.`
                    )
                  }
                )
            )
        )

        Promise.allSettled(promises).then((results) => {
          const { allData, allErrors } = extractFulfilledValues(results)

          const resourcesIDs = []

          for (const curr of allData) {
            const { provider_name, data } = curr
            if (provider_name === 'aws') {
              data.forEach((row) => {
                if (row.Value) {
                  resourcesIDs.push('aws/' + row.Value)
                }
              })
            } else if (provider_name === 'gcp') {
              data.forEach((row) => {
                if (row.resource_global_name !== null) {
                  resourcesIDs.push('gcp/' + row.resource_global_name)
                }
              })
            } else if (provider_name === 'azure') {
              data.forEach((subscription) => {
                subscription.rows.forEach((row) => {
                  resourcesIDs.push('azure/' + row[0])
                })
              })
            }
          }

          bigResolve({ allData: resourcesIDs, allErrors })
        })
      })
    },

    getResourcesForRegion(organizationID, provider, region, startDate, endDate) {
      const providerAccountsStore = useProviderAccountsStore()
      const accounts = this.filteredProviderAccounts.filter(
        (account) => account.provider_name === provider
      )
      const promises = accounts.map(
        (account) =>
          providerAccountsStore.getResources(organizationID, account.id, startDate, endDate, {
            ...this.getAPIFilters,
            cloud_region: {
              operator: 'is',
              value: region
            }
          })
      )
      return new Promise((resolve) => {
        Promise.allSettled(promises).then((results) => {
          const { allData, allErrors } = extractFulfilledValues(results)

          resolve({ allData, allErrors })
        })
      })
    },

    // Related to filters.
    /**
     * Get the filters for an organization.
     * @param {Number} organizationID The organization ID.
     * @returns {Promise<AxiosResponse>}
     */
    loadFilters(organizationID) {
      return new Promise((resolve, reject) => {
        filtersApi.getFilters(organizationID).then((res) => {
          if (res.data) {
            this.views = res.data
            resolve(res.data)
          } else {
            reject(new Error('Could not load filters.'))
          }
        })
      })
    },

    /**
     * Get the filter for an organization.
     * @param {Number} organizationID The organization ID.
     * @param {Number} filterID The filter ID.
     * @returns {Promise<AxiosResponse>}
     */
    getFilter(organizationID, filterID) {
      return filtersApi.getFilter(organizationID, filterID)
    },

    createFilters(organizationID, payload) {
      return filtersApi.createFilters(organizationID, payload)
    },

    /**
     * Edit filters.
     * @param {Number} organizationID The organization ID.
     * @param {Number} filterID The filters ID.
     * @param {{ name: String, rules: Object }} payload
     * @returns {Promise<AxiosResponse>}
     */
    editFilters(organizationID, filterID, payload) {
      return new Promise((resolve) => {
        filtersApi.editFilters(organizationID, filterID, payload).then(({ data }) => {
          this.setSelectedView(data)
          this.loadFilters(organizationID).then(() => {
            resolve()
          })
        })
      })
    },

    /**
     * Delete filter.
     * @param {Number} organizationID The organization ID.
     * @param {Number} filterID The filter ID.
     * @returns {Promise<AxiosResponse>}
     */
    deleteFilter(organizationID, filterID) {
      return filtersApi.deleteFilter(organizationID, filterID)
    },

    /**
     * Get the tags for an organization.
     * @param {Number} organizationID The organization ID.
     * @returns {Promise<AxiosResponse>}
     */
    getTags(organizationID) {
      return new Promise((resolve, reject) => {
        tagsApi.getTags(organizationID).then(
          ({ data }) => {
            const virtualTags = data.map((tag) => ({
              ...tag,
              values: tag.values.map((val) => JSON.parse(val))
            }))
            this.virtualTags = virtualTags
            resolve(virtualTags)
          },
          (e) => {
            reject(e.message === 'canceled' ? 'canceled' : 'Could not get virtual tags.')
          }
        )
      })
    },

    /**
     * Create a tag for an organization.
     * @param {Number} organizationID The organization ID.
     * @param {{ name: String, values: Array<String> }} payload
     * @returns {Promise<AxiosResponse>}
     */
    createTag(organizationID, payload) {
      return tagsApi.createTag(organizationID, payload)
    },

    /**
     * Edit a tag for an organization.
     * @param {Number} organizationID The organization ID.
     * @param {Number} tagID The tag ID.
     * @param {{ name: String, values: Array<String> }} payload
     * @returns {Promise<AxiosResponse>}
     */
    editTag(organizationID, tagID, payload) {
      return tagsApi.editTag(organizationID, tagID, payload)
    },

    /**
     * Delete a tag for an organization.
     * @param {Number} organizationID The organization ID.
     * @param {Number} tagID The tag ID.
     * @returns {Promise<AxiosResponse>}
     */
    deleteTag(organizationID, tagID) {
      return tagsApi.deleteTag(organizationID, tagID)
    },

    // Business Metrics

    /**
     * Get the business metrics for an organization.
     * @param {Number} organizationID The organization ID.
     * @returns {Promise<Array<{ id: Number, name: String, metrics: Object, created: String, last_modified: String }>>}
     */
    getBusinessMetrics(organizationID) {
      this.businessMetrics = []
      return new Promise((resolve, reject) => {
        businessMetricsApi.getBusinessMetrics(organizationID).then(
          ({ data }) => {
            const businessMetrics = data.map((businessMetric) => ({
              ...businessMetric,
              metrics: {
                ...businessMetric.metrics,
                metrics: businessMetric.metrics.metrics.map((m) => ({
                  ...m,
                  date: new Date(m.date)
                }))
              }
            }))
            this.businessMetrics = businessMetrics
            resolve(businessMetrics)
          },
          (e) => {
            reject(e.message === 'canceled' ? 'canceled' : 'Could not get business metrics.')
          }
        )
      })
    },

    /**
     * Create a business metric for an organization.
     * @param {Number} organizationID The organization ID.
     * @param {{ name: String, values: Array<String> }} payload
     * @returns {Promise<AxiosResponse>}
     */
    createBusinessMetric(organizationID, payload) {
      return businessMetricsApi.createBusinessMetric(organizationID, payload)
    },

    /**
     * Edit a business metric for an organization.
     * @param {Number} organizationID The organization ID.
     * @param {Number} businessMetricID The business metric ID.
     * @param {{ name: String, values: Array<String> }} payload
     * @returns {Promise<AxiosResponse>}
     */
    editBusinessMetric(organizationID, businessMetricID, payload) {
      return businessMetricsApi.editBusinessMetric(organizationID, businessMetricID, payload)
    },

    /**
     * Delete a business metric for an organization.
     * @param {Number} organizationID The organization ID.
     * @param {Number} businessMetricID The business metric ID.
     * @returns {Promise<AxiosResponse>}
     */
    deleteBusinessMetric(organizationID, businessMetricID) {
      return businessMetricsApi.deleteBusinessMetric(organizationID, businessMetricID)
    }
  }
})

export { useDashboardStore }
