import organizationsApi from '@/api/organizations'
import { defineStore } from 'pinia'

export const VALID_STRIPE_STATUSES = ['trial', 'paid']

export const QUOTAS = {
  free: 3000,
  pro: 10000,
  business: 20000
}

export const useOrganizationStore = defineStore({
  id: 'organization',

  state: () => ({
    organization: null,
    organizations: [],
    loading: {
      organization: false,
      organizations: false
    }
  }),

  getters: {
    getCurrentOrganization: ({ organizations }) => organizations[0],
    getOrganizationStatus: ({ getCurrentOrganization }) => getCurrentOrganization.stripe_status,
    getOrganizationID: ({ getCurrentOrganization }) => getCurrentOrganization.id,
    isOrganizationAllowed: ({ getCurrentOrganization }) =>
      VALID_STRIPE_STATUSES.includes(getCurrentOrganization?.stripe_status),
    getTrackedUsage: ({ getCurrentOrganization }) => QUOTAS[getCurrentOrganization.stripe_status]
  },

  actions: {
    /**
     * Get a list of organizations.
     * @param {Number|undefined} offset Minimum 0.
     * @param {Number|undefined} limit Minimum 1 Maximum 100.
     * @param {Object} filters `name`, `created_since`, `created_until`.
     * @returns {Promise<AxiosResponse>}
     */
    getOrganizations(offset, limit, filters) {
      this.loading.organizations = true
      return new Promise((resolve) => {
        organizationsApi.getPage(offset, limit, filters).then(({ data }) => {
          this.organizations = data
          this.loading.organizations = false
          resolve(data)
        })
      })
    },

    /**
     * Get details of an organization.
     * @param {Number} organizationID The organization ID.
     * @returns {Promise<AxiosResponse>}
     */
    getOrganization(organizationID) {
      this.loading.organization = true
      return new Promise((resolve) => {
        organizationsApi.get(organizationID).then(({ data }) => {
          this.organization = data
          this.loading.organization = false
          resolve(data)
        })
      })
    },

    /**
     * Create an organization.
     * @param {Object} data `name`.
     * @returns {Promise<AxiosResponse>}
     */
    createOrganization(data) {
      return new Promise((resolve) => {
        organizationsApi.create(data).then((res) => {
          resolve(res.data)
        })
      })
    },

    /**
     * Modification of an organization.
     * @param {Number} organizationID The organization ID.
     * @param {Object} data `name`.
     * @returns {Promise<AxiosResponse>}
     */
    editOrganization(organizationID, data) {
      this.loading.organization = true
      return new Promise((resolve, reject) => {
        organizationsApi
          .edit(organizationID, data)
          .then((res) => {
            this.organization = res.data
            resolve(res.data)
          }, reject)
          .finally(() => {
            this.loading.organization = false
          })
      })
    },

    /**
     * Delete an organization. Their account and data will be entirely erased.
     * @param {Number} organizationID The organization ID.
     * @returns {Promise<AxiosResponse>}
     */
    deleteOrganization(organizationID) {
      return new Promise((resolve, reject) => {
        organizationsApi.delete(organizationID).then(resolve, reject)
      })
    },

    getStripePortal(organizationID) {
      return new Promise((resolve, reject) => {
        organizationsApi.getStripePortal(organizationID).then(({ data }) => {
          resolve(data)
        }, reject)
      })
    }
  }
})
