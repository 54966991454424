import { computed } from 'vue'
import { PAYMENT_OPTIONS, TERMS, useRecommendations } from '@/common/cost-dashboard/recommendations.js'
import { useRecommendationsStore } from '@/store/recommendations.js'
import { storeToRefs } from 'pinia'

export const SAVINGS_TYPES = {
  COMPUTE_SP: 'Compute Savings Plans',
  'Compute_Savings_Plan': 'Compute Savings Plan'
}

export function getServiceFromCommitment(obj) {
  return obj.currentResourceType.split('Savings')[0]
}

function extractAWSSavingsPlan(account, obj) {
  const provider = 'aws'

  const { SavingsPlansPurchaseRecommendationSummary, LookbackPeriodInDays, PaymentOption, SavingsPlansType, TermInYears } = obj
  const details = obj.SavingsPlansPurchaseRecommendationDetails[0]

  const estimatedHourlyCommitment = parseFloat(SavingsPlansPurchaseRecommendationSummary.HourlyCommitmentToPurchase)
  const link = `https://us-east-1.console.aws.amazon.com/costmanagement/home?region=us-east-1#/savings-plans/recommendations?lookbackPeriodInDays=${LookbackPeriodInDays}&paymentOption=${PaymentOption}&scope=undefined&spType=${SavingsPlansType}&termInYears=${TermInYears.endsWith('S') ? TermInYears.slice(0, -1) : TermInYears}&tokens=%5B%5D`

  return {
    account: {
      id: account.id,
      name: account.name,
      provider
    },
    provider,
    id: obj.SavingsPlansPurchaseRecommendationDetails[0].RecommendationDetailId,
    type: obj.SavingsPlansType,
    paymentOption: PAYMENT_OPTIONS[obj.PaymentOption],
    term: TERMS[obj.TermInYears],
    monthlySavings: parseFloat(SavingsPlansPurchaseRecommendationSummary.EstimatedMonthlySavingsAmount),
    currentOnDemandSpend: parseFloat(SavingsPlansPurchaseRecommendationSummary.CurrentOnDemandSpend),
    estimatedHourlyCommitment,
    remainingOnDemandSpend: parseFloat(details.remainingOnDemandSpend),
    estimatedMonthlyCommitment: estimatedHourlyCommitment * 730,
    estimatedSavingsPercentage: parseFloat(SavingsPlansPurchaseRecommendationSummary.EstimatedSavingsPercentage).toFixed(2),
    link
  }
}

const useSavingsPlans = () => {
  const savingsPlans = computed(() => {
    const recommendationsStore = useRecommendationsStore()
    const plans = []

    for (const row of recommendationsStore.allSavingsPlans) {
      const { account, data } = row
      for (const plan of data) {
        plans.push(extractAWSSavingsPlan(account, plan))
      }
    }

    return plans
  })

  return { savingsPlans }
}

const useCommitmentRecommendations = (type, selectedAccounts) => {
  const commitmentRecommendations = computed(() => {
    const { savingsPlans } = useSavingsPlans()
    const { recommendations } = useRecommendations(type, selectedAccounts)
    const ret = []
    const gcpRecommendations = recommendations.value.filter(({ provider }) => provider === 'gcp')
    const awsRecommendations = recommendations.value.filter(({ provider }) => provider === 'aws')
    const azureRecommendations = recommendations.value.filter(({ provider }) => provider === 'azure')

    // Handle GCP recommendations
    const gcpXorGroupIds = Array.from(
      new Set(gcpRecommendations.map(({ more }) => more.xorGroupId))
    )

    for (const xorGroupId of gcpXorGroupIds) {
      const forThisXorGroupId = gcpRecommendations
        .filter(({ more }) => more.xorGroupId === xorGroupId)
        .sort((a, b) => (a.monthlySavings > b.monthlySavings ? -1 : 1))
      const topLevel = forThisXorGroupId.shift()
      topLevel.savingsType = 'CUD'
      const savings = Array.from(
        new Set(
          forThisXorGroupId
            .map(({ monthlySavings }) => monthlySavings)
            .filter((val) => val !== topLevel.monthlySavings)
        )
      )
      const alternatives = savings.map((val) =>
        forThisXorGroupId.find(({ monthlySavings }) => monthlySavings === val)
      )
      ret.push({
        ...topLevel,
        alternatives
      })
    }

    // Handle AWS recommendations
    const resourceTypes = Array.from(
      new Set(awsRecommendations.map(({ more }) => more.resourceType))
    )

    for (const resourceType of resourceTypes) {
      const topLevel = awsRecommendations.find(({ more }) => more.resourceType === resourceType)
      const alternatives = savingsPlans.value.filter(({ type }) => type === resourceType).sort((a, b) => a.monthlySavings > b.monthlySavings ? 1 : -1)
      const highestSavings = alternatives.map(({ monthlySavings }) => monthlySavings).sort((a, b) => a > b ? -1 : 1).shift()
      topLevel.savingsType = SAVINGS_TYPES[resourceType]
      topLevel.monthlySavings = highestSavings || topLevel.monthlySavings
      ret.push({
        ...topLevel,
        alternatives
      })
    }

    // Handle Azure recommendations
    const subscriptionIDs = Array.from(
      new Set(azureRecommendations.map(({ more }) => more.subscription))
    )

    for (const subscriptionID of subscriptionIDs) {
      const forThisSubscription = azureRecommendations
        .filter(({ more }) => more.subscription === subscriptionID)
        .sort((a, b) => a.monthlySavings > b.monthlySavings ? -1 : 1)
      const topLevel = forThisSubscription[0]
      topLevel.savingsType = SAVINGS_TYPES[topLevel.more.savingsType]
      ret.push({
        ...topLevel,
        alternatives: forThisSubscription
      })
    }

    return ret
  })

  return { commitmentRecommendations }
}

const useRecommendationsSavings = (type, selectedAccounts) => {
  const recommendationsStore = useRecommendationsStore()
  const { period } = storeToRefs(recommendationsStore)

  const savingsPerProvider = computed(() => {
    const { commitmentRecommendations } = useCommitmentRecommendations(type, selectedAccounts)

    const providers = {}

    for (const recommendation of commitmentRecommendations.value) {
      const { provider, monthlySavings } = recommendation

      if (!(provider in providers)) {
        providers[provider] = 0
      }

      providers[provider] += monthlySavings * (period.value === 'yearly' ? 12 : 1)
    }

    return providers
  })

  const totalSavings = computed(() => {
    return Object.values(savingsPerProvider.value).reduce((acc, curr) => acc + curr, 0)
  })

  return { savingsPerProvider, totalSavings }
}

export { useCommitmentRecommendations, useRecommendationsSavings }
