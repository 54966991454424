import instance from '@/api'
import mappingUrl from '@/api/modules/public/mapping'

export const diagramApiUrl = mappingUrl

export default {
  get(path) {
    return instance.get(diagramApiUrl + path)
  },

  getPublic(path, options = {}) {
    return instance.get(`/${path}`, options)
  }
}
