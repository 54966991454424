import instance from '@/api'
import mainServerUrl from '@/api/modules/main-server'
import providerServerUrl from '@/api/modules/provider-server'

export const providerAccountsApiUrl = mainServerUrl + 'provider_accounts'

export default {
  get(id) {
    return instance.get(providerAccountsApiUrl + '/' + id)
  },

  getPage(offset, limit, filters) {
    return instance.get(providerAccountsApiUrl, {
      cancelToken: null,
      params: {
        ...filters,
        offset,
        limit
      }
    })
  },

  addProviderAccount(payload) {
    return instance.post(providerAccountsApiUrl, payload)
  },

  updateProviderAccount(id, payload) {
    return instance.patch(providerAccountsApiUrl + '/' + id, payload)
  },

  deleteProviderAccount(id) {
    return instance.delete(providerAccountsApiUrl + '/' + id)
  },

  getSync(providerAccountID) {
    return instance.get(providerAccountsApiUrl + '/' + providerAccountID + '/sync')
  },

  getSyncHistory(providerAccountID, params) {
    return instance.get(providerAccountsApiUrl + '/' + providerAccountID + '/sync/history', {
      params
    })
  },

  // Provider server endpoints

  verify(providerAccountID) {
    return instance.post(providerServerUrl + 'provider_accounts/' + providerAccountID + '/verify')
  },

  sync(providerAccountID) {
    return instance.post(providerServerUrl + 'provider_accounts/' + providerAccountID + '/sync')
  },

  // only AWS regions and services for now ...

  getRegions(providerAccountID) {
    return instance.get(providerServerUrl + 'provider_accounts/' + providerAccountID + '/regions')
  },

  getServices(providerAccountID) {
    return instance.get(providerServerUrl + 'provider_accounts/' + providerAccountID + '/services')
  }
}
