<script setup>
import { useAppStore } from '@/store/app'
import { computed, ref, watch } from 'vue'

const appStore = useAppStore()

const invisible = ref(true)

const getStyle = computed(() => {
  const { val, max } = appStore.loading
  if (max === 0) {
    return { opacity: 0, width: 0 }
  }
  return {
    opacity: invisible.value ? 0 : 1,
    width: (val / max) * 100 + '%'
  }
})

watch(
  () => appStore.loading.val,
  (val) => {
    if (val >= appStore.loading.max) {
      setTimeout(() => {
        // Is it still necessary to hide the bar? Maybe something came later and still needs loading.
        // i.e. a Promise fulfilled and triggered more loading.
        if (val >= appStore.loading.max) {
          invisible.value = true
        }
      }, 500)
    } else {
      invisible.value = false
    }
  }
)
</script>

<template>
  <div class="app-loading">
    <div class="app-loading__bar" :style="getStyle" />
  </div>
</template>

<style scoped>
.app-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 32;
}

.app-loading__bar {
  background-color: var(--primary);
  height: 3px;
  position: relative;
  transition:
    width 0.1s ease,
    opacity 0.5s ease;
}

.app-loading__bar:after {
  content: '';
  background-image: linear-gradient(to left, #fbfbfb0d, #fbfbfb4d, #fbfbfb99, #fbfbfb4d, #fbfbfb0d);
  position: absolute;
  left: -45%;
  height: 100%;
  width: 45%;
  animation: loading 2s infinite;
}

@keyframes loading {
  0% {
    left: -45%;
  }
  100% {
    left: 100%;
  }
}
</style>
