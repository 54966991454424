<script setup>
import { computed } from 'vue'
import { useOrganizationMembership } from '@/common/organization-membership.js'
import { OTooltip } from '@oruga-ui/oruga-next'

const props = defineProps({
  black: {
    type: Boolean,
    required: false,
    default: false
  }
})

const { organizationID } = useOrganizationMembership(false)

const getLogoImage = computed(() => {
  return `/holori/navbar${!props.black ? '-white' : ''}.svg`
})

const getLink = computed(() => {
  if (organizationID) {
    return {
      name: 'Overview',
      params: {
        organizationID
      }
    }
  } else {
    return '/'
  }
})
</script>

<template>
  <o-tooltip label="Go back home" position="right">
    <RouterLink :to="getLink">
      <picture>
        <img :src="getLogoImage" alt="Holori Logo" width="150" />
      </picture>
    </RouterLink>
  </o-tooltip>
</template>
