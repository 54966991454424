import moment from 'moment'

export const periods = [
  {
    label: 'Last 7 days',
    range: [moment().subtract(7, 'days'), moment()]
  },
  {
    label: 'Last 30 days',
    range: [moment().subtract(30, 'days'), moment()]
  },
  {
    label: 'This month',
    range: [moment().startOf('month'), moment().endOf('month')]
  },
  {
    label: 'Last month',
    range: [
      moment().subtract(1, 'months').startOf('month'),
      moment().subtract(1, 'months').endOf('month')
    ]
  },
  {
    label: 'Last 3 months',
    range: [moment().subtract(3, 'months').startOf('month'), moment()]
  },
  {
    label: 'Last 6 months',
    range: [moment().subtract(6, 'months').startOf('month'), moment()]
  }
]
