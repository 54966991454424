import { computed, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useOruga } from '@oruga-ui/oruga-next'
import EventBus from '@/common/eventBus'
import { makeTitle } from '@/common/meta'

const toastIcons = {
  warning: 'alert',
  info: 'information',
  success: 'check-circle',
  danger: 'close-circle'
}

const toastOptions = {
  hasIcon: true,
  iconSize: 'medium',
  rootClass: 'toast-notification',
  position: 'bottom',
  closable: true,
  duration: 5000,
  queue: true
}

export const useMixins = () => {
  const route = useRoute()
  const router = useRouter()
  const oruga = useOruga()

  function setPageTitle(title) {
    EventBus.dispatch('setPageTitle', title)
    nextTick(() => {
      document.title = makeTitle(title)
    })
  }

  function navigate(name, more = {}) {
    router.push({ name, ...more }).catch(() => {})
  }

  function updateQuery(update, push = false) {
    const call = push ? router.push : router.replace
    return call({
      ...route,
      query: {
        ...route.query,
        ...update
      }
    }).catch(() => {})
  }

  function updateQueryJS(update) {
    const { location } = window
    const searchParams = new URLSearchParams(location.search)
    for (const key in update) {
      if (update[key] === null) {
        searchParams.delete(key)
      } else {
        searchParams.set(key, update[key])
      }
    }
    const { protocol, host, pathname } = location
    const newURL = protocol + '//' + host + pathname + '?' + searchParams
    window.history.replaceState({}, '', newURL)
  }

  function closeAllToasts() {
    oruga.notification.closeAll({ action: 'closeAll' })
  }

  function toast(options) {
    oruga.notification.open({
      ...toastOptions,
      icon: toastIcons[options.variant || 'info'],
      ...options
    })
  }

  function displayErrors(errors) {
    if (!Array.isArray(errors) || !errors.length) {
      return
    }

    if (!('errorMessages' in window)) {
      window.errorMessages = []
    }

    window.errorMessages.push(errors)
  }

  function scrollToBottom() {
    setTimeout(() => {
      window.scrollTo({
        top: document.body.offsetHeight,
        behavior: 'smooth'
      })
    }, 300)
  }

  function backToProjects() {
    navigate('Projects')
  }

  function notAllowed(e) {
    if (e?.response?.status === 403) {
      toast({
        variant: 'warning',
        message: 'You are not allowed to do that.'
      })
    } else {
      toast({
        variant: 'warning',
        message: e.toString()
      })
    }
  }

  function notFound() {
    navigate('NotFound')
  }

  return {
    setPageTitle,
    navigate,
    updateQuery,
    updateQueryJS,
    closeAllToasts,
    toast,
    displayErrors,
    scrollToBottom,
    backToProjects,
    notAllowed,
    notFound
  }
}

const checkPassword = (password) => {
  if (!password) {
    return 'No password'
  }

  if (!password.length >= 8) {
    return 'Minimum 8 characters required.'
  }

  if (!/\d+/.test(password)) {
    return 'At least 1 digit required.'
  }

  if (!/[A-Z]+/.test(password)) {
    return 'At least 1 uppercase letter required.'
  }

  if (!/[a-z]+/.test(password)) {
    return 'At least 1 lowercase letter required.'
  }

  return ''
}

export const usePassword = ({
  password,
  newPassword,
  passwordConfirmation,
  newPasswordConfirmation
}) => {
  const passwordPattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}'

  const passwordMessage = computed(() => checkPassword(password?.value || ''))
  const newPasswordMessage = computed(() => checkPassword(newPassword?.value || ''))
  const passwordConfirmationMessage = computed(() =>
    checkPassword(passwordConfirmation?.value || '')
  )
  const newPasswordConfirmationMessage = computed(() =>
    checkPassword(newPasswordConfirmation?.value || '')
  )

  return {
    passwordPattern,
    passwordMessage,
    newPasswordMessage,
    passwordConfirmationMessage,
    newPasswordConfirmationMessage
  }
}

export function usePreviousRoute() {
  const router = useRouter()
  const previousRoute = computed(() => {
    const backURL = router.options.history.state.back
    return router.resolve({ path: backURL })
  })

  return { previousRoute }
}