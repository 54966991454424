import { ref, computed } from 'vue'
import { useAccountStore } from '@/store/account'
import { useMemberStore } from '@/store/member'
import { useOrganizationStore } from '@/store/organization.js'
import { useRoute, useRouter } from 'vue-router'
import { useMixins } from './mixins'
import { availableRoles } from '@/common/utils.js'
import { storeToRefs } from 'pinia'

export const useOrganizationMembership = (check = true) => {
  const route = useRoute()
  const router = useRouter()
  const { navigate } = useMixins()

  const membershipRole = ref('')

  const accountStore = useAccountStore()
  const memberStore = useMemberStore()
  const organizationStore = useOrganizationStore()

  const { member } = storeToRefs(memberStore)

  const organizationID = Number(route.params.organizationID) || organizationStore.getOrganizationID
  const memberID = accountStore.account?.id

  if (check) {
    memberStore.getMember(organizationID, memberID).then(
      (membership) => {
        // User is a member of this organization.
        membershipRole.value = membership.role

        if (typeof check === 'function') {
          check()
        }
      },
      () => {
        router.replace({
          name: 'NotFound'
        })
      }
    )
  }

  function goTo(name, more = {}) {
    navigate(name, {
      ...more,
      params: {
        ...(more.params || {}),
        organizationID
      }
    })
  }

  const isAllowed = computed(() => (permission) => {
    const role = availableRoles.find((r) => r.name === member.value?.role)
    if (role) {
      return role.permissions.includes(permission)
    }
    return false
  })

  return { membershipRole, organizationID, memberID, goTo, isAllowed }
}
