import instance from '@/api'
import { organizationsApiUrl } from '@/api/organizations/index.js'

export default {
  /**
   * Get the tags for an organization.
   * @param {Number} organizationID The organization ID.
   * @returns {Promise<AxiosResponse>}
   */
  getTags(organizationID) {
    return instance.get(`${organizationsApiUrl}/${organizationID}/tags`)
  },

  /**
   * Get a tag for an organization.
   * @param {Number} organizationID The organization ID.
   * @param {Number} tagID The tag ID.
   * @returns {Promise<AxiosResponse>}
   */
  getTag(organizationID, tagID) {
    return instance.get(`${organizationsApiUrl}/${organizationID}/tags/${tagID}`)
  },

  /**
   * Create a tag for an organization.
   * @param {Number} organizationID The organization ID.
   * @param {{ name: String, values: Array<String> }} payload
   * @returns {Promise<AxiosResponse>}
   */
  createTag(organizationID, payload) {
    return instance.post(`${organizationsApiUrl}/${organizationID}/tags`, payload)
  },

  /**
   * Edit a tag for an organization.
   * @param {Number} organizationID The organization ID.
   * @param {Number} tagID The tag ID.
   * @param {{ name: String, values: Array<String> }} payload
   * @returns {Promise<AxiosResponse>}
   */
  editTag(organizationID, tagID, payload) {
    return instance.put(`${organizationsApiUrl}/${organizationID}/tags/${tagID}`, payload)
  },

  /**
   * Delete a tag for an organization.
   * @param {Number} organizationID The organization ID.
   * @param {Number} tagID The tag ID.
   * @returns {Promise<AxiosResponse>}
   */
  deleteTag(organizationID, tagID) {
    return instance.delete(`${organizationsApiUrl}/${organizationID}/tags/${tagID}`)
  }
}
