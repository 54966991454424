import instance from '@/api'
import { organizationsApiUrl } from '@/api/organizations/index.js'

export default {
  /**
   * Get the filters for an organization.
   * @param {Number} organizationID The organization ID.
   * @returns {Promise<AxiosResponse>}
   */
  getFilters(organizationID) {
    return instance.get(`${organizationsApiUrl}/${organizationID}/filters`)
  },

  /**
   * Get the filter for an organization.
   * @param {Number} organizationID The organization ID.
   * @param {Number} filterID The filter ID.
   * @returns {Promise<AxiosResponse>}
   */
  getFilter(organizationID, filterID) {
    return instance.get(`${organizationsApiUrl}/${organizationID}/filters/${filterID}`)
  },

  /**
   * Edit filters.
   * @param {Number} organizationID The organization ID.
   * @param {Number} filterID The filters ID.
   * @param {{ name: String, rules: Object }} payload
   * @returns {Promise<AxiosResponse>}
   */
  editFilters(organizationID, filterID, payload) {
    return instance.put(`${organizationsApiUrl}/${organizationID}/filters/${filterID}`, payload)
  },

  /**
   * Create filters.
   * @param {Number} organizationID The organization ID.
   * @param {{ name: String, rules: Object }} payload
   * @returns {Promise<AxiosResponse>}
   */
  createFilters(organizationID, payload) {
    return instance.post(`${organizationsApiUrl}/${organizationID}/filters`, payload)
  },

  /**
   * Delete filter.
   * @param {Number} organizationID The organization ID.
   * @param {Number} filterID The filter ID.
   * @returns {Promise<AxiosResponse>}
   */
  deleteFilter(organizationID, filterID) {
    return instance.delete(`${organizationsApiUrl}/${organizationID}/filters/${filterID}`)
  }
}
