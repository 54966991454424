<script setup>
import { ref, onMounted } from 'vue'
import { OButton, OLoading } from '@oruga-ui/oruga-next'

defineOptions({
  name: 'CalendlyModal'
})

const loading = ref(true)

const calendlyRef = ref(null)

onMounted(() => {
  const calendlyScript = document.createElement('script')
  calendlyScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
  calendlyScript.onload = () => {
    calendlyRef.value.querySelector('iframe').onload = () => {
      setTimeout(() => {
        loading.value = false
      }, 500)
    }
  }
  document.head.appendChild(calendlyScript)
})
</script>

<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Get a dedicated onboarding</p>
    </header>
    <section class="modal-card-body is-relative">
      <!-- Début de widget en ligne Calendly -->
      <o-loading :active="loading" :full-page="false" />
      <div
        ref="calendlyRef"
        class="calendly-inline-widget"
        data-url="https://calendly.com/holori/15min"
        style="min-width: 320px; height: 600px"
      />
      <!-- Fin de widget en ligne Calendly -->
    </section>
    <footer class="modal-card-foot">
      <o-button class="ml-auto" variant="primary" icon-left="close" @click="$emit('close')">
        close
      </o-button>
    </footer>
  </div>
</template>
